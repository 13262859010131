import React from "react";
import { ArFilters } from "./ArFilters";
import { ArTable } from "./ArTable";
import { ArExportDialog } from "./ArExportDialog";
import { ArCommentDialog } from "./ArCommentDialog";

const ArAgingCLerkPage = (props: any) => {
  const {
    setDate,
    date,
    dateType,
    selectedCorporation,
    setSelectedCorporation,
    multiSelect,
    setMultiSelect,
    setDateType,
    selectFilter,
    setSelectFilter,
    exportDialog,
    commentDialog,
  } = props;
  return (
    <div className={"main-content"}>
      <div className="content-header">
        <ArFilters
          setDate={setDate}
          setSelectedCorporation={setSelectedCorporation}
          corporation={selectedCorporation}
          date={date}
          dateType={dateType}
          multiSelect={multiSelect}
          setMultiSelect={setMultiSelect}
          setDateType={setDateType}
          selectFilter={selectFilter}
          setSelectFilter={setSelectFilter}
        />
      </div>
      <ArTable multiSelect={multiSelect} />
      {commentDialog && <ArCommentDialog />}
      {exportDialog && <ArExportDialog multiSelect={multiSelect} />}
    </div>
  );
};

export default ArAgingCLerkPage;
