/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import ReactApexChart from "react-apexcharts";
import { convertDaysIntoDuration } from "../companySummary/utils/weekdata";
import { useEffect, useState } from "react";
import {
  formatAmount,
  percentConverterFixed,
} from "../../../utils/helper/cellFormatter";
import { useSelector } from "react-redux";
import PipSummaryNotes from "./PipSummaryNotes";
import { getMaxRoundedValue } from "../companySummary/utils/operations";
import { dottedLine } from "../../../utils/helper/charts";
const chartColors = [
  { color: "#609DD4", label: "Plan Revenue" },
  { color: "#0AB156", label: "Actual Revenue PIP" },
  { color: "#FB986B", label: "Last Year Revenue" },
  { color: "#FCF30A", label: "Original Goal" },
  { color: "#609DD4", label: "% to Plan PIP" },
  { color: "#FB986B", label: "% to Last Year PIP" },
];
const PipSummaryBranchChart = (props) => {
  // const weekLabels = useSelector((state) => state.filterReducer.weekLabels);
  let weekLabels = props.branchData?.map((d) => d.WeekEndingDate);
  const [branchSeriesData, setBranchSeriesData] = useState([]);
  const [markerColors, setMarkerColors] = useState([]);
  const [chartAxisOptions, setChartAxisOptions] = useState([]);
  const [options, setOptions] = useState({
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    title: {},
    legend: {
      position: "top",
      show: false,
      horizontalAlign: "right",
    },
    xaxis: {
      categories: weekLabels,
      labels: {
        show: true,
        style: {
          fontSize: "5px",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          fontSize: "7px",
        },
        formatter: (v) => formatAmount(v),
      },
    },
    stroke: {
      width: 2,
      dashArray: [0, 0, 0, 0, 2, 2],
    },
    colors: chartColors.map((c) => c.color),
    grid: {
      show: false,
    },
    tooltip: {
      enabled: props.enableTooltip,
    },
  });

  useEffect(() => {
    setOptions({
      ...options,
      markers: {
        discrete: markerColors,
        hover: {
          size: 2,
          sizeOffset: 2,
        },
      },
      yaxis: chartAxisOptions,
      xaxis: {
        categories: weekLabels,
        labels: {
          show: true,
          style: {
            fontSize: "5px",
          },
        },
      },
    });
  }, [markerColors, chartAxisOptions]);

  useEffect(() => {
    let markers = [];
    let planrevenue = [],
      lastYearRevenue = [],
      actualRevenue = [],
      originalGoal = [],
      percentToPlan = [],
      percentToLastYear = [];
    weekLabels?.map((date) => {
      const v = props.branchData?.find(
        (d) => d.WeekEndingDate === date.toString()
      );
      if (v) {
        if (v.DotsColor) markers.push({ date: date, color: v.DotsColor });
        planrevenue.push(v.PlanRevenue);
        actualRevenue.push(v.ActualPIPRevenue);
        lastYearRevenue.push(v.Lastyearrevenue);
        originalGoal.push(v.ActualGoalAmount);
        percentToPlan.push(v["% TO Plan"]);
        percentToLastYear.push(v["% TO LAST Year"]);
      }
    });
    const max = getMaxRoundedValue(
      Math.max(
        ...planrevenue,
        ...actualRevenue,
        ...lastYearRevenue,
        ...originalGoal
      )
    );
    setChartAxisOptions([
      {
        floating: true,
        seriesName: "Plan Revenue",
        labels: {
          show: false,
          style: {
            fontSize: "7px",
          },
          formatter: (v) => formatAmount(v),
        },
        max: max,
        min:0
      },
      {
        floating: true,
        seriesName: "Actual Revenue PIP",
        labels: {
          show: false,
          formatter: (v) => formatAmount(v),
        },
        max: max,
        min: 0
      },
      {
        seriesName: "Last Year Revenue",
        labels: {
          show: true,
          style: {
            fontSize: "7px",
          },
          formatter: (v) => formatAmount(v),
        },
        max: max,
        min: 0,
      },
      {
        floating: true,
        seriesName: "Original Goal",
        labels: {
          show: false,
          formatter: (v) => formatAmount(v),
        },
        max: max,
        min:0
      },
      {
        opposite: true,
        seriesName: "% to Plan PIP",
        labels: {
          show: true,
          style: {
            fontSize: "7px",
          },
          formatter: (v) => percentConverterFixed(v),
        },
        max: 5,
        min:0
      },
      {
        floating: true,
        opposite: true,
        seriesName: "% to Last Year PIP",
        labels: {
          show: false,
          formatter: (v) => percentConverterFixed(v),
        },
        max: 5,
        min:0
      },
    ]);
    const seriesData = [
      { name: "Plan Revenue", data: planrevenue },
      { name: "Actual Revenue PIP", data: actualRevenue },
      { name: "Last Year Revenue", data: lastYearRevenue },
      { name: "Original Goal", data: originalGoal },
      { name: "% to Plan PIP", data: percentToPlan },
      { name: "% to Last Year PIP", data: percentToLastYear },
    ];
    setBranchSeriesData(seriesData);
    const markerOptions = [];
    markers.map((marker) => {
      for (let index = 1; index < 6; index++) {
        markerOptions.push({
          seriesIndex: index,
          dataPointIndex: weekLabels.indexOf(marker.date),
          fillColor: marker.color,
          strokeColor: marker.color,
          size: 2,
          shape: "circle",
        });
      }
    });
    setMarkerColors(markerOptions);
  }, [props.branchData]);

  useEffect(() => {
    setOptions({
      ...options,
      tooltip: {
        enabled: props.enableTooltip,
      },
    });
  }, [props.enableTooltip]);

  return (
    <div className="region-chart  w-[96%] sm:max-lg:w-[48.5%] lg:w-[32.5%] flex flex-col gap-2 mb-5 ">
      <div className="chart-header text-xs flex w-full gap-2">
        <div className="status-area flex w-[36%] flex-col justify-end text-xs">
          Current Status:
          <div
            className={`w-full border border-slate-400 flex items-center justify-center h-8 text-center ${
              props.branchMilestone.StatusColor !== "#ffff00"
                ? "text-white"
                : ""
            } font-semibold`}
            style={{
              background: props.branchMilestone.StatusColor,
            }}
          >
            {props.branchMilestone.Milestone}
          </div>
        </div>
        <div className="flex justify-center name-area">
          <div
            className={`chart-header-title text-center text-sm flex items-center justify-center ${
              props.region
                ? "bg-[#285680]"
                : props.district
                ? "bg-cyan-400"
                : props.legacy
                ? "bg-tertiary"
                : "bg-[#00B85F]"
            }`}
            style={{
              background: props.branchMilestone?.BranchColor,
            }}
          >
            {props.branchMilestone.Location}
          </div>
        </div>
        <div className="manager-area">
          <div>
            Manager Name:{" "}
            {props.region || props.district
              ? props.branchMilestone.ManagerName
              : props.branchMilestone.FirstName}
          </div>
          <div>{props.branchMilestone.JobTitle}</div>
          <div>
            Work Time:
            {convertDaysIntoDuration(props.branchMilestone.DaysWorked)}
          </div>
        </div>
      </div>
      <div className="region-chart-area flex flex-col border border-slate-400">
        <div className="colors-area flex gap-1 text-[8px] p-1">
          {chartColors.map((v, key) => (
            <div className="color-box flex items-center gap-1" key={key}>
              <div
                className={
                  dottedLine(v.label)
                    ? `w-4 border-dotted border-y-2 border-x-0 border-[${v.color}]`
                    : "color-circle h-[8px] w-[8px] rounded-full"
                }
                style={{ backgroundColor: `${v.color}` }}
              ></div>
              <div className={"color-name"}>{v.label}</div>
            </div>
          ))}
        </div>
        <div
          className={"h-full flex-grow  overflow-x-scroll overflow-y-hidden"}
        >
          <ReactApexChart
            options={options}
            series={branchSeriesData}
            type="line"
            height={250}
            width={1500}
          />
        </div>
        {props.executive && (
          <PipSummaryNotes
            notes={props.branchMilestone.Notes}
            noteId={props.branchMilestone.noteId}
            location={{
              type: props.locationType,
              name: props.branchMilestone.Location,
            }}
          />
        )}
      </div>
    </div>
  );
};

export default PipSummaryBranchChart;
