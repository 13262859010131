/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { convertDaysIntoDuration } from "./utils/weekdata";
import { sortByKeyCompanySummary } from "../../../utils/helper/cellFormatter";
import SummaryChartBranch from "./SummaryChartBranch";

export const CompanyBranchCharts = ({
  branchesData,
  adaBranchData,
  dataMode,
  revenueData,
  branchesList,
  sortValue,
  labels,
  setTotalType,
  setSelectedBranch,
  setLevel,
  sameMaxValue,
  rawData,
  pipMilestonesData,
  branchesPipData,
  executive,
  wip,
  goal,
}: any) => {
  const branchData = rawData?.filter((d: any) => {
    return branchesList.includes(d.Location);
  });

  const gp: any =
    branchData
      ?.map((d: any) => {
        if (d.GrossProfit) return d.GrossProfit;
      })
      .filter((e: any) => e !== undefined) || [];
  const rev: any =
    branchData
      ?.map((d: any) => {
        if (d.Revenue) return d.Revenue;
      })
      .filter((e: any) => e !== undefined) || [];
  // const maxGp = getMaxRoundedValue(Math.max(...gp));
  // const maxRev = getMaxRoundedValue(Math.max(...rev));
  // const minGp = getMinRoundedValue(Math.min(...gp));
  // const minRev = getMinRoundedValue(Math.min(...rev));
  const getRevenue = (val: any) => {
    return val?.length > 0 ? val[0] : null;
  };

  const getMilestonesData = (location: any) => {
    return (
      [...pipMilestonesData]?.find((d: any) => d.Location === location) ?? null
    );
  };

  return (
    <section className="cs-branch-charts">
      <div className={"w-full cs-branch-charts flex wrap"}>
        {Object.keys(branchesData).length > 0
          ? sortByKeyCompanySummary(
              Object.values(revenueData).flat(),
              sortValue
            ).map((v: any, key) => {
              const data: any = branchesData[v?.Branch];
              if (
                !!branchesData[v?.Branch] &&
                branchesList.includes(v?.Branch)
              ) {
                const managerArr: any =
                  Object.values(data).length > 0 ? Object.values(data)[0] : [];
                const salesManagerDays = convertDaysIntoDuration(
                  managerArr[0]?.BranchManagerDays ?? ""
                );
                const branchManagerDays = convertDaysIntoDuration(
                  managerArr[0]?.SalesManagerDays ?? ""
                );
                return (
                  <div className={"summary-chart-box"} key={v.Branch}>
                    <SummaryChartBranch
                      name={{ label: v?.Branch ?? "" }}
                      data={data}
                      manager={managerArr[0]?.BranchManager ?? "VACANT"}
                      dataMode={dataMode}
                      branchManagerDays={salesManagerDays}
                      labels={labels}
                      salesManagerDays={branchManagerDays}
                      sortValue={sortValue}
                      salesManager={managerArr[0]?.SalesManager ?? "VACANT"}
                      revenue={getRevenue(revenueData[v?.Branch])}
                      setTotalType={setTotalType}
                      setSelectedBranch={setSelectedBranch}
                      setLevel={setLevel}
                      location={{
                        name: v?.Branch,
                        type: "Branch",
                        level: "Branch",
                      }}
                      regionManager={managerArr[0]?.RegionManager ?? ""}
                      districtManager={managerArr[0]?.DistrictManager ?? ""}
                      sameMaxValue={sameMaxValue}
                      pipMilestonesData={getMilestonesData(v?.Branch)}
                      pipData={branchesPipData?.[v?.Branch] ?? null}
                      executive={executive}
                      wip={wip}
                      goal={goal}
                    />
                  </div>
                );
              } else {
                return "";
              }
            })
          : ""}
      </div>
      <div className={"w-full cs-branch-charts flex wrap"}>
        {Object.keys(adaBranchData).length > 0
          ? sortByKeyCompanySummary(
              Object.values(revenueData).flat(),
              sortValue
            ).map((v: any, key) => {
              const data: any = adaBranchData[v?.Branch];
              if (
                !!adaBranchData[v?.Branch] &&
                branchesList.includes(v?.Branch)
              ) {
                const managerArr: any =
                  Object.values(data).length > 0 ? Object.values(data)[0] : [];
                const salesManagerDays = convertDaysIntoDuration(
                  managerArr[0]?.BranchManagerDays ?? ""
                );
                const branchManagerDays = convertDaysIntoDuration(
                  managerArr[0]?.SalesManagerDays ?? ""
                );
                return (
                  <div className={"summary-chart-box"} key={v.Branch}>
                    <SummaryChartBranch
                      name={{ label: v?.Branch ?? "" }}
                      data={data}
                      manager={managerArr[0]?.BranchManager ?? "VACANT"}
                      dataMode={dataMode}
                      branchManagerDays={salesManagerDays}
                      labels={labels}
                      salesManagerDays={branchManagerDays}
                      sortValue={sortValue}
                      salesManager={managerArr[0]?.SalesManager ?? "VACANT"}
                      revenue={getRevenue(revenueData[v?.Branch])}
                      setTotalType={setTotalType}
                      setSelectedBranch={setSelectedBranch}
                      setLevel={setLevel}
                      location={{
                        name: v?.Branch,
                        type: "ADA_Branch",
                        level: "ADA",
                      }}
                      regionManager={managerArr[0]?.RegionManager ?? ""}
                      districtManager={managerArr[0]?.DistrictManager ?? ""}
                      sameMaxValue={sameMaxValue}
                      pipMilestonesData={getMilestonesData(v?.Branch)}
                      pipData={branchesPipData?.[v?.Branch] ?? null}
                      executive={executive}
                      wip={wip}
                      goal={goal}
                    />
                  </div>
                );
              } else {
                return "";
              }
            })
          : ""}
      </div>
    </section>
  );
};
