/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { useEffect, useState } from "react";
import { Loader } from "../components/common/Loader";
import {
  APIMonthSlicerData,
  APIMonthlyData,
  APIProjectionData,
  APIWeeklyData,
  APIYtdData,
} from "../api/kearl-kurve";
import ReactApexChart from "react-apexcharts";
import {
  formatAmount,
  formatPercentValue,
} from "../utils/helper/cellFormatter";
import { ActionIcon, Select, Switch, Tabs } from "@mantine/core";
import { DailyNumberSelector } from "../components/modules/dailyNumber/DailyNumberBranchSelector";
import "../assets/scss/DailyNumber.scss";

export const KearlKurveReport = (props: any) => {
  const currentYear = new Date().getFullYear();
  const yearValues: any = [];
  for (let year = currentYear; year > currentYear - 3; year--) {
    yearValues.push(year.toString());
  }
  const [monthValues, setMonthValues] = useState([]);
  const reportTypes: any = {
    projection: "Kearl Kurve Projection",
    ytd: "Kearl Kurve YTD",
    monthly: "Kearl Kurve Monthly",
    weekly: "Kearl Kurve Weekly Comparison",
  };

  const [loading, setLoading] = useState(false);
  const [activeReport, setActiveReport] = useState<string | null>(
    reportTypes.projection
  );
  const [selectedTimeline, setSelectedTimeline] = useState<any>(
    currentYear.toString()
  );
  const [selectedMonthTimeline, setSelectedMonthTimeline] = useState<
    string | null
  >("");
  const [branchesList, setBranchesList] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState("Company");
  const [selectedBranchName, setSelectedBranchName] = useState("All");
  const [hideSellingManager, setHideSellingManager] = useState(false);
  const [maximumChartScale, setMaximumChartScale] = useState(true);
  const [level, setLevel] = useState("Company");
  const [apiData, setApiData] = useState<any>(null);
  const [chartData, setChartData] = useState<any>({
    sellingManager: [],
    otherManager: [],
    model: [],
    sbm: [],
  });
  const [axisValues, setAxisValues] = useState<any>({});
  const [series, setSeries] = useState<any>([]);
  const [options, setOptions] = useState<any>({
    chart: {
      type: "line",
      stacked: false,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    grid: {
      borderColor: "#eee",
      yaxis: {
        lines: {
          show: false,
        },
      },
      xaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      size: [0, 0, 4, 4],
      colors: ["", "", "#0000FF", "#000"],
      strokeWidth: 0,
      hover: {
        size: 4,
      },
    },
    stroke: {
      width: 1,
    },
    colors: ["#000", "#3886BB", "#6fab80", "#e68053"],
    tooltip: {
      enabled: true,
      shared: false,
      intersect: true,
      custom: ({ series, seriesIndex, dataPointIndex, w }: any) => {
        var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
        if (!data) return `<div>No data</div>`;
        return `<div
          style="
          font-size: 12px;
          padding: 5px;
          background: #ddd;
          ">
            <div>Branch: ${data.branch}</div>
            <div>Revenue: ${formatAmount(data.x)}</div>
            <div>OI%: ${formatPercentValue(data.y)}</div>
          </div>`;
      },
      style: {
        fontSize: "12px",
      },
    },

    legend: {
      // show: false,
    },
    yaxis: {
      min: -10,
      max: 15,
      tickAmount: 5,
      decimalsInFloat: 0,
      title: {
        text: "OI%",
      },
      labels: {
        formatter: (v: any) => v.toFixed(0) + "%",
      },
    },
    xaxis: {
      type: "numeric",
      min: 0,
      max: 7000000,
      tickAmount: 14,
      title: {
        text: "Revenue",
      },
      labels: {
        formatter: (v: any) => "$" + v / 1000000 + "M",
      },
      tooltip: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: true,
      offsetX: 0,
      offsetY: -5,
      style: {
        fontSize: "7.5px",
        colors: ["#aaa"],
      },
      background: {
        enabled: false,
      },
      formatter: function (val: any, opt: any) {
        return opt.w.globals.initialSeries[opt.seriesIndex].data[
          opt.dataPointIndex
        ].branch;
      },
    },
  });

  useEffect(() => {
    document.title = "Kearl Kurve";
    APIMonthSlicerData().then((res) => {
      const months = res.data.map((d: any) => d.MonthYear);
      setMonthValues(months);
      setSelectedMonthTimeline(months[0]);
    });
  }, []);

  useEffect(() => {
    setChartSeries();
  }, [selectedBranchName]);

  useEffect(() => {
    if (apiData) {
      const { line, plot } = apiData;
      const resultCurrent = {
        sellingManager: [],
        otherManager: [],
        model: [],
        sbm: [],
      } as any;
      const maxOI =
        Math.ceil((Math.max(...plot.map((d: any) => d["OI%"])) * 100) / 5) * 5;
      const minOI =
        Math.floor((Math.min(...plot.map((d: any) => d["OI%"])) * 100) / 5) * 5;
      setAxisValues({
        min: minOI < -20 ? minOI : -20,
        max: maxOI > 15 ? maxOI : 15,
      });
      plot?.forEach((item: any) => {
        if (
          item?.Types === "Current week" ||
          item?.Jobtitle === "Selling Branch Manager"
        ) {
          resultCurrent.sellingManager.push({
            x: item.Revenue ?? 0,
            y: item["OI%"] ? item["OI%"] * 100 : 0,
            branch: item.Branch,
            revenue: item.Revenue ?? 0,
          });
        } else {
          resultCurrent.otherManager.push({
            x: item.Revenue ?? 0,
            y: item["OI%"] ? item["OI%"] * 100 : 0,
            branch: item.Branch,
            revenue: item.Revenue ?? 0,
          });
        }
      });
      line?.forEach((item: any) => {
        if (item.LineType === "Model") {
          resultCurrent.model.push({
            x:
              activeReport === reportTypes.weekly
                ? item.Revenue / 21
                : item.Revenue,
            y: item["OI%"] * 100,
          });
        } else {
          resultCurrent.sbm.push({
            x:
              activeReport === reportTypes.weekly
                ? item.Revenue / 21
                : item.Revenue,
            y: item["OI%"] * 100,
          });
        }
      });
      setChartData(resultCurrent);
      setChartOptions();
    }
    setLoading(false);
  }, [apiData]);

  useEffect(() => {
    setChartSeries();
  }, [chartData, hideSellingManager]);

  useEffect(() => {
    setChartSeries();
    setChartOptions();
  }, [maximumChartScale]);

  useEffect(() => {
    setChartOptions();
  }, [axisValues]);

  useEffect(() => {
    setSelectedBranchName("All");
    fetchReportData();
  }, [activeReport, selectedTimeline, selectedMonthTimeline, selectedBranch]);

  const setChartOptions = () => {
    const minYaxis =
      activeReport === reportTypes.weekly
        ? -100
        : maximumChartScale
        ? axisValues.min
        : -6;
    const maxYaxis =
      activeReport === reportTypes.weekly
        ? 40
        : maximumChartScale
        ? axisValues.max
        : 15;
    const maxXaxis = activeReport === reportTypes.weekly ? 1000000 : 7000000;
    const yaxisTickAmount =
      activeReport === reportTypes.weekly
        ? 7
        : maximumChartScale
        ? activeReport === reportTypes.projection
          ? 6
          : 7
        : 5;
    const xaxisTickAmount = activeReport === reportTypes.weekly ? 2 : 7;
    const markerColors =
      activeReport === reportTypes.weekly
        ? ["", "", "#6fab80", "#e68053"]
        : ["", "", "#0000FF", "#000"];
    setOptions({
      ...options,
      yaxis: {
        min: minYaxis,
        max: maxYaxis,
        tickAmount: yaxisTickAmount,
        decimalsInFloat: 0,
        title: {
          text: "OI%",
        },
        labels: {
          formatter: (v: any) => v.toFixed(0) + "%",
        },
      },
      xaxis: {
        min: 0,
        max: maxXaxis,
        tickAmount: xaxisTickAmount,
        title: {
          text: "Revenue",
        },
        labels: {
          formatter: (v: any) => "$" + v / 1000000 + "M",
        },
        tooltip: {
          enabled: false,
        },
      },
      markers: {
        size: [0, 0, 4, 4],
        colors: markerColors,
        strokeWidth: 0,
        strokeColors: "#ddd",
        hover: {
          size: 4,
        },
      },
      legend: {
        show: activeReport === reportTypes.weekly,
        position: "top",
        horizontalAlign: "left",
        customLegendItems: ["Current Week", "First Week"],
        markers: {
          fillColors: ["#6fab80", "#e68053"],
        },
      },
      annotations: {
        yaxis: [
          {
            y: 0,
            borderColor: "#aaa",
            strokeDashArray: 0,
          },
        ],
        xaxis: [
          {
            x: 3000000,
            borderColor: "#aaa",
            strokeDashArray: 0,
          },
        ],
      },
    });
  };

  const setChartSeries = () => {
    let sellingManager = hideSellingManager
      ? []
      : activeReport === reportTypes.weekly || maximumChartScale
      ? chartData?.sellingManager
      : chartData?.sellingManager.filter((d: any) => d.y >= -10 && d.y <= 15);
    let otherManager =
      activeReport === reportTypes.weekly || maximumChartScale
        ? chartData?.otherManager
        : chartData?.otherManager.filter((d: any) => d.y >= -10 && d.y <= 15);
    if (selectedBranchName !== "All") {
      sellingManager = sellingManager.filter(
        (data: any) => data.branch === selectedBranchName
      );
      otherManager = otherManager.filter(
        (data: any) => data.branch === selectedBranchName
      );
      activeReport === reportTypes.weekly &&
        setIsIncremental(sellingManager[0]?.y > otherManager[0]?.y);
    }
    setSeries([
      {
        name: "MODEL",
        type: "line",
        data: chartData?.model?.filter((data: any) => data.x <= 8000000),
      },
      {
        name: "SBM/MODEL",
        type: "line",
        data: chartData?.sbm?.filter((data: any) => data.x <= 8000000),
      },
      {
        name:
          activeReport === reportTypes.weekly
            ? "Current week"
            : "Selling Branch Manager",
        type: "scatter",
        data: sellingManager,
      },
      {
        name:
          activeReport === reportTypes.weekly ? "First week" : "Branch Manager",
        type: "scatter",
        data: otherManager,
      },
    ]);
  };

  const fetchReportData = () => {
    setLoading(true);
    switch (activeReport) {
      case reportTypes.projection:
        console.log("projection");
        APIProjectionData(
          selectedTimeline,
          selectedBranch !== "Company" && selectedBranch != "ADA_Branch"
            ? selectedBranch
            : null,
          level
        ).then((res) => setApiData(res.data));
        break;
      case reportTypes.ytd:
        console.log("ytd");
        APIYtdData(
          selectedTimeline,
          selectedBranch !== "Company" && selectedBranch != "ADA_Branch"
            ? selectedBranch
            : null,
          level
        ).then((res) => setApiData(res.data));
        break;
      case reportTypes.monthly:
        console.log("monthly");
        APIMonthlyData(
          selectedMonthTimeline,
          selectedBranch !== "Company" && selectedBranch != "ADA_Branch"
            ? selectedBranch
            : null,
          level
        ).then((res) => setApiData(res.data));
        break;
      case reportTypes.weekly:
        console.log("weekly");
        APIWeeklyData(
          selectedBranch !== "Company" && selectedBranch != "ADA_Branch"
            ? selectedBranch
            : null,
          level
        ).then((res) => setApiData(res.data));
        break;
    }
  };

  const [isIncremental, setIsIncremental] = useState(false);
  useEffect(() => {
    setIsIncremental(
      selectedBranchName === "All"
        ? apiData?.comparisonData?.currentWeek >
            apiData?.comparisonData?.firstWeek
        : series[2].data[0]?.y > series[3].data[0].y
    );
  }, [apiData]);

  const resetFilters = () => {
    setSelectedTimeline(yearValues[2]);
    setSelectedMonthTimeline(monthValues?.[0]);
    setSelectedBranch("Company");
    setSelectedBranchName("All");
    setHideSellingManager(false);
    setMaximumChartScale(true);
    setLevel("Company");
  };
  console.log(selectedBranch, level);
  console.log(branchesList);
  return (
    <div className="h-[100vh]">
      <div>
        <Tabs value={activeReport} onTabChange={setActiveReport}>
          <Tabs.List>
            {reportTypes &&
              Object.keys(reportTypes).map((report) => {
                return (
                  <Tabs.Tab
                    value={reportTypes[report]}
                    pb="xs"
                    key={report + "-tab"}
                  >
                    <span className="text-xs">{reportTypes[report]}</span>
                  </Tabs.Tab>
                );
              })}
          </Tabs.List>
          {reportTypes &&
            Object.keys(reportTypes).map((report: string) => {
              return (
                <Tabs.Panel
                  value={reportTypes[report]}
                  pb="xs"
                  key={report + "-panel"}
                >
                  <div className="flex flex-col justify-center items-center md:flex-row md:justify-between md:items-baseline px-5 relative">
                    <div className="absolute top-0 sm:top-1 left-0 flex items-center">
                      <ActionIcon onClick={resetFilters}>
                        <i
                          className="material-icons"
                          style={{ fontSize: "32px" }}
                        >
                          chevron_left
                        </i>
                      </ActionIcon>
                      <ActionIcon>
                        <a
                          href="https://app.powerbi.com/groups/d9341e4a-2be0-4eb6-8eb1-5a9ed297ac2a/reports/60da792a-d41f-48b5-a002-80366a29f241/ReportSectionfdcec90ba28704f33c71?experience=power-bi"
                          target={"_blank"}
                          rel="noreferrer"
                        >
                          <i className="text-slate-600 material-icons text-[28px]">
                            home
                          </i>
                        </a>
                      </ActionIcon>
                    </div>
                    <div className="text-secondary text-md sm:text-xl font-bold pl-10 self-start">
                      {reportTypes[report]}
                    </div>
                    <div>
                      <DailyNumberSelector
                        selectedBranch={selectedBranch}
                        setSelectedBranch={setSelectedBranch}
                        setLevel={setLevel}
                        level={level}
                        setBranches={setBranchesList}
                        kearlKurve={true}
                      />
                    </div>
                    <div className="flex flex-row flex-wrap gap-2 items-center md:flex-col md:gap-0">
                      {activeReport !== reportTypes.monthly &&
                        activeReport !== reportTypes.weekly && (
                          <div className="w-full sm:w-44">
                            <Select
                              value={selectedTimeline}
                              onChange={setSelectedTimeline}
                              data={yearValues}
                              size="xs"
                              label="Timeline"
                            />
                          </div>
                        )}
                      {activeReport === reportTypes.monthly && (
                        <div className="w-full sm:w-44">
                          <Select
                            value={selectedMonthTimeline}
                            onChange={setSelectedMonthTimeline}
                            data={monthValues}
                            size="xs"
                            label="Timeline"
                          />
                        </div>
                      )}
                      <div className="w-full sm:w-44">
                        <Select
                          value={selectedBranchName}
                          onChange={(v: any) => setSelectedBranchName(v)}
                          data={["All", ...branchesList]}
                          size="xs"
                          label="Branch"
                        />
                      </div>
                      {activeReport !== reportTypes.weekly && (
                        <div className="mt-2 cursor-pointer">
                          <Switch
                            className="cursor-pointer"
                            checked={hideSellingManager}
                            label="Hide Selling Manager"
                            size="xs"
                            onChange={(event) =>
                              setHideSellingManager(event.currentTarget.checked)
                            }
                          />
                        </div>
                      )}
                      {activeReport !== reportTypes.weekly && (
                        <div className="mt-2 cursor-pointer">
                          <Switch
                            className="cursor-pointer"
                            checked={maximumChartScale}
                            label="Maximize Chart Scale"
                            size="xs"
                            onChange={(event) =>
                              setMaximumChartScale(event.currentTarget.checked)
                            }
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </Tabs.Panel>
              );
            })}
        </Tabs>
      </div>
      <div className="relative px-10">
        <div>
          <ReactApexChart options={options} series={series} height={700} />
        </div>
        {activeReport === reportTypes.weekly && (
          <div className="absolute bottom-32 right-20">
            {isIncremental ? (
              <i className="material-icons text-[3rem] text-green-600">
                arrow_upward
              </i>
            ) : (
              <i className="material-icons text-[3rem] text-red-600">
                arrow_downward
              </i>
            )}
          </div>
        )}
      </div>
      <Loader loading={loading} />
    </div>
  );
};
