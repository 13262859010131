/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

export const EXECUTIVE_COMPANY_MENUS = [
  {
    path: "",
    label: "Dashboard",
    link: "https://app.powerbi.com/groups/d9341e4a-2be0-4eb6-8eb1-5a9ed297ac2a/reports/c40b3e9b-da49-4cdb-b1e3-7c69ccf9e162/ReportSection42bfb911359bdc6dfe94",
    active: false,
  },
  {
    path: "/daily-numbers",
    label: "Daily Number",
    link: "https://as-reports.gritfeat.com/daily-numbers",
    active: false,
  },
  {
    path: "",
    label: "Open Assignment",
    link: "https://app.powerbi.com/groups/d9341e4a-2be0-4eb6-8eb1-5a9ed297ac2a/reports/5afe3c33-e15d-4e22-b957-4fd570e5b2ef/ReportSection",
    active: false,
  },
  {
    path: "",
    label: "Staff Productivity",
    link: "https://app.powerbi.com/groups/d9341e4a-2be0-4eb6-8eb1-5a9ed297ac2a/reports/885e752d-acdc-45e1-aa51-657cc9a7220f/ReportSection",
    active: false,
  },
  {
    path: "",
    label: "Available Employees",
    link: "https://app.powerbi.com/groups/d9341e4a-2be0-4eb6-8eb1-5a9ed297ac2a/reports/60da792a-d41f-48b5-a002-80366a29f241/ReportSection20fd3d6f07cdd265814a?bookmarkGuid=Bookmark54318d2f4aabcb0b1c0b",
    active: false,
  },
  {
    path: "",
    label: "Top Clients",
    link: "https://as-reports.gritfeat.com/top-clients",
    active: false,
  },
  {
    path: "",
    label: "Comparison",
    link: "https://app.powerbi.com/groups/d9341e4a-2be0-4eb6-8eb1-5a9ed297ac2a/reports/16317061-27b7-4017-8d48-6511527e6b87/ReportSection",
    active: false,
  },
  {
    path: "/weekly-report",
    label: "Weekly Report",
    link: "https://as-reports.gritfeat.com/weekly-report",
    active: false,
  },
  {
    path: "",
    label: "Sales Activity",
    link: "https://app.powerbi.com/groups/d9341e4a-2be0-4eb6-8eb1-5a9ed297ac2a/reports/60da792a-d41f-48b5-a002-80366a29f241/ReportSectionab4bd0c53b3b1fcb7cfc?bookmarkGuid=Bookmark23a6e20635c0e07aa2eb",
    active: false,
  },
  {
    path: "/executive-company-summary",
    label: "Executive Company Summary",
    link: "https://as-reports.gritfeat.com/company-summary",
    active: false,
  },
  {
    path: "",
    label: "Branch Dashboard",
    link: "https://app.powerbi.com/groups/d9341e4a-2be0-4eb6-8eb1-5a9ed297ac2a/reports/03b19f1c-18a8-4c8f-b8f3-e3f238f61948/ReportSectionfdcec90ba28704f33c71",
    active: false,
  },
  {
    path: "/executive-pip-summary",
    label: "Executive PIP Summary",
    link: "https://as-reports.gritfeat.com/executive-pip-summary",
    active: false,
  },
];

export const COMPANY_MENUS = [
  {
    path: "",
    label: "Dashboard",
    link: "https://app.powerbi.com/groups/d9341e4a-2be0-4eb6-8eb1-5a9ed297ac2a/reports/c40b3e9b-da49-4cdb-b1e3-7c69ccf9e162/ReportSection42bfb911359bdc6dfe94",
    active: false,
  },
  {
    path: "/daily-numbers",
    label: "Daily Number",
    link: "https://as-reports.gritfeat.com/daily-numbers",
    active: false,
  },
  {
    path: "",
    label: "Open Assignment",
    link: "https://app.powerbi.com/groups/d9341e4a-2be0-4eb6-8eb1-5a9ed297ac2a/reports/5afe3c33-e15d-4e22-b957-4fd570e5b2ef/ReportSection",
    active: false,
  },
  {
    path: "",
    label: "Staff Productivity",
    link: "https://app.powerbi.com/groups/d9341e4a-2be0-4eb6-8eb1-5a9ed297ac2a/reports/885e752d-acdc-45e1-aa51-657cc9a7220f/ReportSection",
    active: false,
  },
  {
    path: "",
    label: "Available Employees",
    link: "https://app.powerbi.com/groups/d9341e4a-2be0-4eb6-8eb1-5a9ed297ac2a/reports/60da792a-d41f-48b5-a002-80366a29f241/ReportSection20fd3d6f07cdd265814a?bookmarkGuid=Bookmark54318d2f4aabcb0b1c0b",
    active: false,
  },
  {
    path: "",
    label: "Top Clients",
    link: "https://as-reports.gritfeat.com/top-clients",
    active: false,
  },
  {
    path: "",
    label: "Comparison",
    link: "https://app.powerbi.com/groups/d9341e4a-2be0-4eb6-8eb1-5a9ed297ac2a/reports/16317061-27b7-4017-8d48-6511527e6b87/ReportSection",
    active: false,
  },
  {
    path: "/weekly-report",
    label: "Weekly Report",
    link: "https://as-reports.gritfeat.com/weekly-report",
    active: false,
  },
  {
    path: "",
    label: "Sales Activity",
    link: "https://app.powerbi.com/groups/d9341e4a-2be0-4eb6-8eb1-5a9ed297ac2a/reports/60da792a-d41f-48b5-a002-80366a29f241/ReportSectionab4bd0c53b3b1fcb7cfc?bookmarkGuid=Bookmark23a6e20635c0e07aa2eb",
    active: false,
  },
  {
    path: "/company-summary",
    label: "Company Summary",
    link: "https://as-reports.gritfeat.com/company-summary",
    active: false,
  },
  {
    path: "",
    label: "Branch Dashboard",
    link: "https://app.powerbi.com/groups/d9341e4a-2be0-4eb6-8eb1-5a9ed297ac2a/reports/03b19f1c-18a8-4c8f-b8f3-e3f238f61948/ReportSectionfdcec90ba28704f33c71",
    active: false,
  },
];

// Company Summary

export const CS_BRANCHES_TOP = [
  {
    label: "Company",
  },
];

export const CS_BRANCHES_MID = [
  {
    label: "Art's",
  },
  {
    label: "Kia's",
  },
];

export const CS_BRANCHES_LOW = [
  {
    label: "AZ NV CA (Dolly)",
  },
  {
    label: "TX NM GA SC (Alex)",
  },
  {
    label: "TX AL LS (Chris)",
  },
  {
    label: "UT ID CO (Jake)",
  },
  {
    label: "UT IA IL KS (Tyler)",
  },
  {
    label: "WA IA NB (Lu Ann)",
  },
];

export const CHART_BRANCHES = [
  { label: "Art's" },
  { label: "Kia's" },
  { label: "AZ NV CA (Dolly)" },
  { label: "TX NM GA SC (Alex)" },
  { label: "TX AL LS (Chris)" },
  { label: "UT ID CO (Jake)" },
  { label: "UT IA IL KS (Tyler)" },
  { label: "WA IA NB (Lu Ann)" },
];

export const BRANCH_CHART_LABELS = [
  { label: "St. George" },
  { label: "Tolleson" },
  { label: "Tooele" },
  { label: "Tremonton" },
  { label: "Tucson" },
  { label: "Twin Falls" },
  { label: "Utah County" },
  { label: "West Valley" },
  { label: "Wichita" },
];

// Daily number

export const DN_BRANCHES_TOP = [
  {
    label: "Ascend Staffing",
  },
];

export const DN_BRANCHES_MID = [
  {
    label: "Art's",
  },
  {
    label: "Kia's",
  },
];

export const DN_BRANCHES_LOW = [
  {
    label: "AZ NV CA (Dolly)",
  },
  {
    label: "TX NM GA SC (Alex)",
  },
  {
    label: "TX AL LS (Chris)",
  },
  {
    label: "UT ID CO (Jake)",
  },
  {
    label: "UT IA IL KS (Tyler)",
  },
  {
    label: "WA IA NB (Lu Ann)",
  },
];

export const DAY_OF_THE_WEEK = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
