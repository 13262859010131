/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */
import { Select, Switch } from "@mantine/core";
import BarChart from "./BarChart";
import { useState } from "react";
import { ContentLoader } from "../../common/Loader";

const BranchTopFocusChart = (props: any) => {
  const {
    areaSelected,
    regionSelected,
    subAreaSelected,
    districtSelected,

    // selectedLevelBranch,
    // setSelectedLevelBranch,
    areaList,
    subareaList,
    regionsList,
    districtList,
    branchesList,
    clientList,
    selectedBranch,
    setSelectedBranch,
    selectedClientName,
    setSelectedClientName,
    chartData,
    chartAreaSelected,
    chartSubAreaSelected,
    chartDistrictSelected,
    chartRegionSelected,
    setChartAreaSelected,
    setChartSubAreaSelected,
    setChartDistrictSelected,
    setChartRegionSelected,
    barchartLoading,
  } = props;
  const [selectedYear, setSelectedYear] = useState(2024);

  const filterByClientName =
    selectedClientName === "All"
      ? chartData
      : chartData.filter((d: any) => d.ClientName === selectedClientName);

  const filteredDataByYear = filterByClientName.filter(
    (d: any) => d.CalendarYear === selectedYear
  );

  const countTotal = (data: any[], groupedKey: string, keytoCount: string) => {
    return Object.entries(
      data.flat().reduce((counts: any, entry: any) => {
        const name = entry[groupedKey];
        const count = entry[keytoCount] || 0;
        counts[name] = (counts[name] || 0) + count;
        return counts;
      }, {})
    ).map(([name, total]) => ({ name, total }));
  };

  const barchartData = countTotal(
    filteredDataByYear,
    "calendarweek",
    "GrossProfit"
  );
  const linechartData = countTotal(
    filteredDataByYear,
    "calendarweek",
    "Revenue"
  );

  return (
    <div className="flex flex-col gap-2 ">
      <div className="flex justify-end gap-4 my-1">
        {areaSelected ||
        subAreaSelected ||
        regionSelected ||
        districtSelected ? (
          <></>
        ) : (
          <Select
            label="Area"
            data={["All", ...areaList]}
            className="w-[8rem]"
            size="xs"
            onChange={(value: any) => {
              setChartAreaSelected(value === "All" ? null : value);
              // setAreaSelected(value === "All" ? null : value);
              // setSelectedLevelBranch(value === "All" ? "Company" : value);
              setChartRegionSelected(null);
              setSelectedBranch("All");
              setSelectedClientName("All");
            }}
            value={chartAreaSelected === null ? "All" : chartAreaSelected}
          />
        )}
        {subAreaSelected || regionSelected || districtSelected ? (
          <></>
        ) : (
          <Select
            label="Subarea"
            data={["All", ...subareaList.filter((s: any) => s !== "Ungrouped")]}
            size="xs"
            className="w-[8rem]"
            onChange={(value: any) => {
              setChartSubAreaSelected(value === "All" ? null : value);
              // setAreaSelected(value === "All" ? null : value);
              // setSelectedLevelBranch(value === "All" ? "Company" : value);
              setChartAreaSelected(null);
              setChartRegionSelected(null);
              setChartDistrictSelected(null);
              setSelectedBranch("All");
              setSelectedClientName("All");
            }}
            value={chartSubAreaSelected === null ? "All" : chartSubAreaSelected}
          />
        )}
        {regionSelected || districtSelected ? (
          <></>
        ) : (
          <Select
            label="Region"
            data={[
              "All",
              ...regionsList
                .filter((v: any) => v.Region !== null)
                .map((v: any) => v.Region),
            ]}
            onChange={(value: any) => {
              setChartRegionSelected(value === "All" ? null : value);
              // setRegionSelected(value === "All" ? null : value);
              // setSelectedLevelBranch(value === "All" ? "Company" : value);
              setChartAreaSelected(null);
              setSelectedBranch("All");
              setSelectedClientName("All");
            }}
            value={chartRegionSelected === null ? "All" : chartRegionSelected}
            size="xs"
          />
        )}
        {areaSelected || districtSelected ? (
          <></>
        ) : (
          <Select
            label="Area Branch"
            data={[
              "All",
              ...districtList
                .filter((v: any) => v.District !== null)
                .map((v: any) => v.District),
            ]}
            onChange={(value: any) => {
              setChartDistrictSelected(value === "All" ? null : value);
              // setRegionSelected(value === "All" ? null : value);
              // setSelectedLevelBranch(value === "All" ? "Company" : value);
              setChartRegionSelected(null);
              setChartSubAreaSelected(null);
              setChartAreaSelected(null);
              setSelectedBranch("All");
              setSelectedClientName("All");
            }}
            value={
              chartDistrictSelected === null ? "All" : chartDistrictSelected
            }
            size="xs"
          />
        )}
        <Select
          label="Branch"
          data={["All", ...branchesList]}
          size="xs"
          value={selectedBranch}
          onChange={(value) => {
            setSelectedBranch(value);
            setSelectedClientName("All");
          }}
        />
        <Select
          label="Client Name"
          data={["All", ...clientList]}
          size="xs"
          value={selectedClientName}
          onChange={(value) => setSelectedClientName(value)}
        />
      </div>
      <div className="flex justify-between">
        <div className="border-[1px] border-black  p-1 text-xs flex gap-1 items-center">
          <i className="material-icons  text-[10px] lg:text-md">info</i>
          <span className="text-[10px] lg:text-md">
            {" "}
            New Business: Clients billed in{" "}
            {selectedYear === 2024 ? 2024 : 2023} but not in{" "}
            {selectedYear === 2024 ? 2023 : 2022}
          </span>
        </div>
        <div className=" bg-[#2c90c3] text-white rounded-lg p-1 px-2 text-sm font-bold w-[200px] m-auto text-center">
          New Business
        </div>
        <Switch
          label="Last year new business"
          size="xs"
          className="self-end"
          checked={selectedYear === 2023}
          onChange={() => setSelectedYear(selectedYear === 2024 ? 2023 : 2024)}
        />
      </div>

      <div className="bg-white p-1 relative">
        <div className="flex gap-2 text-xs items-center ">
          <div className="flex items-center gap-1 ">
            <i
              className="material-icons text-[#5097b5]"
              style={{ fontSize: "16px" }}
            >
              brightness_1
            </i>
            Gross Profit
          </div>
          <div className="flex items-center gap-1 ">
            <i
              className="material-icons  text-[#fc9330]"
              style={{ fontSize: "16px" }}
            >
              brightness_1
            </i>
            Revenue
          </div>
        </div>
        {barchartData &&
        barchartData.length > 0 &&
        linechartData &&
        linechartData.length > 0 ? (
          <>
            <BarChart
              selectedYear={selectedYear}
              barchartData={barchartData}
              linechartData={linechartData}
            />
            <ContentLoader loading={barchartLoading} />
          </>
        ) : (
          <div className="h-[270px] flex justify-center items-center gap-1 w-full text-xs text-slate-500">
            <i className="material-icons" style={{ fontSize: "16px" }}>
              search
            </i>
            No Data
          </div>
        )}
      </div>
    </div>
  );
};

export default BranchTopFocusChart;
