/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

export const TABLE_HEADERS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
  "YTD",
  "Cur %",
  "YTD %",
  // 'Curr',
];

export const TABLE_MONTH_HEADERS = () => {
  return [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
};

export const TABLE_OTHER_HEADERS = [
  "YTD",
  "Cur %",
  "YTD %",
  // 'Curr',
];

export const HIGHLIGHTED_PARTICULARS = [
  "Net Sales",
  "Total Temp Expenses",
  "Gross Profit",
  "Total Sales Expenses",
  "Total G&A Expenses",
  "Total Operating Expenses",
  "Operating Income/(Loss)",
  "Net Income/(Loss)",
];
