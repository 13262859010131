/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { CompanySummarySidebar } from "../components/modules/companySummary/CompanySummarySidebar";
import { CompanySummaryCharts } from "../components/modules/companySummary/CompanySummaryCharts";
import "../assets/scss/CompanySummary.scss";
import { CompanyBranchCharts } from "../components/modules/companySummary/CompanyBranchCharts";
import { CompanySummarySelector } from "../components/modules/companySummary/CompanySummarySelector";
import { useEffect, useState } from "react";
import {
  APIAreaTotalData,
  APICompanyTotalData,
  APICompanyTotalRevenue,
  APIBranchTotalData,
  APIRegionTotalData,
  APISubareaTotalData,
  FetchClientsGPRankByLocation,
  FetchClientsRevenueRankByLocation,
  WipAPIAreaTotalData,
  WipAPIBranchTotalData,
  WipAPICompanyTotalData,
  WipAPIRegionTotalData,
  WipAPISubareaTotalData,
  fetchClientDetailsByLocation,
  GoalAPICompanyTotalData,
  GoalAPIAreaTotalData,
  GoalAPISubareaTotalData,
  GoalAPIRegionTotalData,
  GoalAPIBranchTotalData,
  WipAPICompanyTotalRevenue,
  APIDistrictTotalData,
  APIADABranchTotalData,
  APIADATotalData,
} from "../api/company-summary";
import { groupByKey } from "../utils/group";
import {
  getAllCalendarWeeks,
  getCalendarWeekLabels,
  setLoading,
} from "../store/Filter/actions";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../components/common/Loader";
import { labelFormatter } from "../utils/helper/charts";
import { sortByKey } from "../utils/helper/cellFormatter";
import { loadPipSummaryData } from "../store/PipSummary/actions";
import { ActionIcon } from "@mantine/core";
import {
  setEnableTooltip,
  setShowBranchClientsRanking,
  setShowClientsRanking,
  setShowPipSummaryLines,
} from "../store/CompanySummary/actions";

const initialLabels = [
  labelFormatter("Current Year"),
  labelFormatter("Last Year"),
  labelFormatter("Previous Year"),
  labelFormatter("Actual Goal"),
  labelFormatter("12 Months"),
];

const initialState = {
  selectedBranch: "Company",
  level: "Company",
  dataMode: "Revenue",
  subAreaSelected: "",
  areaSelected: "",
  totalType: "Company",
  sortValue: "TWRank",
  sameMaxValue: false,
};

const TotalDataApis = {
  default: {
    companyTotalData: APICompanyTotalData,
    areaTotalData: APIAreaTotalData,
    vpTotalData: APISubareaTotalData,
    regionTotalData: APIRegionTotalData,
    branchTotalData: APIBranchTotalData,
  },
  wip: {
    companyTotalData: WipAPICompanyTotalData,
    areaTotalData: WipAPIAreaTotalData,
    vpTotalData: WipAPISubareaTotalData,
    regionTotalData: WipAPIRegionTotalData,
    branchTotalData: WipAPIBranchTotalData,
  },
  goal: {
    companyTotalData: GoalAPICompanyTotalData,
    areaTotalData: GoalAPIAreaTotalData,
    vpTotalData: GoalAPISubareaTotalData,
    regionTotalData: GoalAPIRegionTotalData,
    branchTotalData: GoalAPIBranchTotalData,
  },
};

export const CompanySummaryReport = (props: any) => {
  const [selectedBranch, setSelectedBranch] = useState("Company");
  const [level, setLevel] = useState("Company");
  const [totalData, setTotalData] = useState({});
  const [areaTotalData, setAreaTotalData] = useState({});
  const [districtTotalData, setDistrictTotalData] = useState({});
  const [regionTotalData, setRegionTotalData] = useState({});
  const [subareaTotalData, setSubareaTotalData] = useState({});
  const [branchesData, setBranchesData] = useState({});
  const [adaTotalData, setAdaTotalData] = useState({});
  const [adaBranchData, setAdaBranchData] = useState({});
  const [dataMode, setDataMode] = useState("Revenue");
  const [hierarchy, setHierarchy] = useState({} as any);
  const [districtList, setDistrictList] = useState([] as any);
  const [regionsList, setRegionsList] = useState([] as any);
  const [areaList, setAreaList] = useState([] as any);
  const [subAreaList, setSubAreaList] = useState([] as any);
  const [subAreaSelected, setSubAreaSelected] = useState<string | null>(null);
  const [areaSelected, setAreaSelected] = useState("");
  const [totalType, setTotalType] = useState("Company");
  const [sortValue, setSortValue] = useState("TWRank");
  const [branchesList, setBranchesList] = useState([] as any);
  const [labels, setLabels] = useState([...initialLabels] as any);
  const [clientsData, setClientsData] = useState<any>();
  const [clientsDetailData, setClientsDetailData] = useState<any>();
  const [clientsDataMode, setClientsDataMode] = useState("Revenue");
  const [clientNumber, setClientNumber] = useState("10");
  const [clientYear, setClientYear] = useState("2024");
  const [sameMaxValue, setSameMaxValue] = useState(false);
  const [regionsRawData, setRegionsRawData] = useState<any>();
  const [districtRawData, setDistrictRawData] = useState<any>();
  const [branchesRawData, setBranchesRawData] = useState<any>();
  const [sameClientRatio, setSameClientRatio] = useState<any>(false);

  const dispatch = useDispatch();
  useEffect(() => {
    document.title = props.executive
      ? "Executive Company Summary"
      : "Company Summary Report";
  }, []);

  const showClientsRanking = useSelector(
    (state: any) => state.companySummaryReducer.showClientsRanking
  );
  const showBranchClientsRanking = useSelector(
    (state: any) => state.companySummaryReducer.showBranchClientsRanking
  );
  const [totalRevenues, setTotalRevenues] = useState({
    company: [],
    district: [],
    region: [],
    area: [],
    branch: [],
    subarea: [],
    ada: [],
    ada_branch: [],
  });
  const [orders, setOrders] = useState({
    company: [],
    region: [],
    district: [],
    area: [],
    branch: [],
  });
  //totals data
  const getCompanyTotal = async () => {
    const res = props.goal
      ? await GoalAPICompanyTotalData()
      : await APICompanyTotalData();
    const data = groupByKey(res.data, "Types");
    setLabels(Object.keys(data) ?? []);
    setTotalData(data);
  };

  const getAreaTotals = async () => {
    const res = props.goal
      ? await GoalAPIAreaTotalData()
      : await APIAreaTotalData();
    const data = groupByKey(res.data, "areaname");
    let areas = {};
    Object.keys(data).forEach((v) => {
      areas = {
        ...areas,
        [v]: groupByKey(data[v], "Types"),
      };
    });
    setAreaTotalData(areas);
  };

  const getRegionTotals = async () => {
    const res = props.goal
      ? await GoalAPIRegionTotalData()
      : await APIRegionTotalData();
    setRegionsRawData(res.data);
    const data = groupByKey(res.data, "Location");
    let regions = {};
    Object.keys(data).forEach((v) => {
      regions = {
        ...regions,
        [v]: groupByKey(data[v], "Types"),
      };
    });
    setRegionTotalData(regions);
  };

  const getDistrictTotals = async () => {
    const res = await APIDistrictTotalData();
    setDistrictRawData(res.data);
    const data = groupByKey(res.data, "Location");
    let districts = {};
    Object.keys(data).forEach((v) => {
      districts = {
        ...districts,
        [v]: groupByKey(data[v], "Types"),
      };
    });
    setDistrictTotalData(districts);
  };

  const getSubareaTotals = async () => {
    const res = props.goal
      ? await GoalAPISubareaTotalData()
      : await APISubareaTotalData();
    const data = groupByKey(res.data, "Location");
    let regions = {};
    Object.keys(data).forEach((v) => {
      regions = {
        ...regions,
        [v]: groupByKey(data[v], "Types"),
      };
    });
    setSubareaTotalData(regions);
  };

  // branches data

  const getTotalBranchesData = async () => {
    const res = props.goal
      ? await GoalAPIBranchTotalData()
      : props.wip
      ? await WipAPIBranchTotalData()
      : await APIBranchTotalData();
    setBranchesRawData(res.data);
    const data = groupByKey(res.data, "Location");
    let branches = {};
    Object.keys(data).forEach((v) => {
      branches = {
        ...branches,
        [v]: groupByKey(data[v], "Types"),
      };
    });
    setBranchesData(branches);
  };

  const getADABranchData = async () => {
    const res = await APIADABranchTotalData();
    const data = groupByKey(res.data, "Location");
    let ada = {};
    Object.keys(data).forEach((v) => {
      ada = {
        ...ada,
        [v]: groupByKey(data[v], "Types"),
      };
    });
    setAdaBranchData(ada);
  };
  const getADATotalData = async () => {
    const res = await APIADATotalData();
    const data = groupByKey(res.data, "Location");
    let ada = {};
    Object.keys(data).forEach((v) => {
      ada = {
        ...ada,
        [v]: groupByKey(data[v], "Types"),
      };
    });
    setAdaTotalData(ada);
  };

  const getCompanyTotalData = async (option: string) => {
    dispatch(setLoading(true));
    setSelectedBranch(option);
    await getTotalBranchesData();
    dispatch(setLoading(false));
  };

  const getTotalRevenues = async () => {
    const res: any = props.wip
      ? await WipAPICompanyTotalRevenue()
      : await APICompanyTotalRevenue();
    const temp = {
      ...totalRevenues,
      company: res.data.company[0],
      region: groupByKey(res.data.region, "Region"),
      area: groupByKey(res.data.area, "AreaName"),
      district: groupByKey(res.data.district, "District"),
      branch: groupByKey(res.data.branch, "Branch"),
      subarea: groupByKey(res.data.subarea, "Sub Area Name"),
      ada: res.data.ada,
      ada_branch: groupByKey(res.data.ada_branch, "Branch"),
    };
    const tempOrder: any = {
      branch: res.data.branch.map((v: any) => v.branch),
      region: res.data.region.map((v: any) => v.Region),
      area: res.data.area.map((v: any) => v.AreaName),
      district: res.data.district.map((v: any) => v.district),
      subarea: res.data.subarea.map((v: any) => v["Sub Area Name"]),
      ada_branch: res.data.ada_branch.map((v: any) => v.Branch),
    };
    setTotalRevenues(temp);
    setOrders(tempOrder);
  };
  // initiation
  useEffect(() => {
    loadAllData().then(() => {});
  }, []);

  const getWeekDates = async () => {
    dispatch(await getAllCalendarWeeks("2022", "2024"));
    dispatch(await getCalendarWeekLabels("2024"));
  };

  const loadAllData = async () => {
    dispatch(setLoading(true));
    await Promise.all([
      getWeekDates(),
      getTotalRevenues(),
      getCompanyTotal(),
      getAreaTotals(),
      getSubareaTotals(),
      getRegionTotals(),
      getDistrictTotals(),
      getTotalBranchesData(),
      getADABranchData(),
      getADATotalData(),
    ]);
    dispatch(setLoading(false));
  };

  useEffect(() => {
    setClientNumber("10");
  }, [showBranchClientsRanking, showClientsRanking]);

  useEffect(() => {
    if (showClientsRanking || showBranchClientsRanking) {
      try {
        dispatch(setLoading(true));
        if (dataMode === "NetIncome") {
          setClientsDataMode("Revenue");
          setDataMode("Revenue");
        } else {
          setClientsDataMode(dataMode);
        }
        (async () => {
          let response;
          if (clientsDataMode === "Revenue") {
            response = await FetchClientsRevenueRankByLocation(
              selectedBranch,
              clientNumber,
              clientYear
            );
          } else {
            response = await FetchClientsGPRankByLocation(
              selectedBranch,
              clientNumber,
              clientYear
            );
          }
          setClientsData(response.data);
          const details: any = [];
          const client_details: any = {};
          response.data.map((d: any) => {
            client_details[d.ClientName] = [];
            details.push(
              fetchClientDetailsByLocation(
                d.ClientName,
                clientYear,
                selectedBranch
              )
            );
          });
          const clientDetails = await Promise.all(details);
          response.data.map((d: any, i: any) => {
            client_details[d.ClientName] = groupByKey(
              sortByKey(clientDetails[i].data, {
                type: "CalendarYear",
                isReverse: true,
              }),
              "CalendarYear"
            );
          });
          setClientsDetailData(client_details);
          dispatch(setLoading(false));
        })();
      } catch (err) {
        console.log(err);
        dispatch(setLoading(false));
      }
    }
  }, [
    showClientsRanking,
    showBranchClientsRanking,
    clientNumber,
    clientsDataMode,
    clientYear,
  ]);

  const showPipSummaryLines = useSelector(
    (state: any) => state.companySummaryReducer.showPipSummaryLines
  );
  const milestonesPipData = useSelector(
    (state: any) => state.pipSummaryReducer.milestonesData
  );

  const regionsPipData = useSelector(
    (state: any) => state.pipSummaryReducer.regionsData
  );
  const districtsPipData = useSelector(
    (state: any) => state.pipSummaryReducer.districtsData
  );
  const branchesPipData = useSelector(
    (state: any) => state.pipSummaryReducer.branchesData
  );
  useEffect(() => {
    if (showPipSummaryLines) {
      dispatch(loadPipSummaryData(props.executive, []));
    }
  }, [showPipSummaryLines]);

  const handleBackButton = () => {
    if (showClientsRanking || showBranchClientsRanking) {
      setSelectedBranch("Company");
      setLevel("Company");
      dispatch(setShowClientsRanking(false));
      dispatch(setShowBranchClientsRanking(false));
    } else {
      dispatch(setShowPipSummaryLines(false));
      dispatch(setEnableTooltip(true));
      setSelectedBranch(initialState.selectedBranch);
      setAreaSelected(initialState.areaSelected);
      setSubAreaSelected(initialState.subAreaSelected);
      setLevel(initialState.level);
      setTotalType(initialState.totalType);
      setDataMode(initialState.dataMode);
      setSortValue(initialState.sortValue);
      setSameMaxValue(initialState.sameMaxValue);
    }
  };

  return (
    <main className={"cs-summary-screen"}>
      <section className="cs-summary-section flex">
        <div className="cs-sidebar-section ">
          <CompanySummarySidebar executive={props.executive} />
        </div>
        <div className="main-section flex-grow flex flex-col gap-5   bg-[#e2efed] lg:ml-0">
          <div className={"cs-header"}>
            {/* {(showClientsRanking || showBranchClientsRanking) && ( */}
            <div className="absolute top-0 left-0 flex items-center">
              <ActionIcon onClick={handleBackButton}>
                <i className="material-icons" style={{ fontSize: "32px" }}>
                  chevron_left
                </i>
              </ActionIcon>
              <ActionIcon>
                <a
                  href="https://app.powerbi.com/groups/d9341e4a-2be0-4eb6-8eb1-5a9ed297ac2a/reports/60da792a-d41f-48b5-a002-80366a29f241/ReportSectionfdcec90ba28704f33c71?experience=power-bi"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <i className="text-slate-600 material-icons text-[28px]">
                    home
                  </i>
                </a>{" "}
              </ActionIcon>
            </div>
            {/* )} */}
            <div className="flex align-center cs-title-area">
              <div className="cs-title text-center">
                {props.executive
                  ? "Executive Company Summary"
                  : "Company Summary"}
              </div>
            </div>
            <CompanySummarySelector
              selectedBranch={selectedBranch}
              setSelectedBranch={setSelectedBranch}
              getCompanyTotal={getCompanyTotalData}
              setSortValue={setSortValue}
              sortValue={sortValue}
              dataMode={dataMode}
              setDataMode={setDataMode}
              setLevel={setLevel}
              level={level}
              hierarchy={hierarchy}
              setHierarchy={setHierarchy}
              districtList={districtList}
              setDistrictList={setDistrictList}
              regionsList={regionsList}
              setRegionsList={setRegionsList}
              areaList={areaList}
              setAreaList={setAreaList}
              areaSelected={areaSelected}
              setTotalType={setTotalType}
              totalType={totalType}
              setAreaSelected={setAreaSelected}
              subAreaList={subAreaList}
              setSubAreaList={setSubAreaList}
              subAreaSelected={subAreaSelected}
              setSubAreaSelected={setSubAreaSelected}
              branchesList={branchesList}
              setBranchesList={setBranchesList}
              clientsDataMode={clientsDataMode}
              setClientsDataMode={setClientsDataMode}
              clientNumber={clientNumber}
              setClientNumber={setClientNumber}
              clientYear={clientYear}
              setClientYear={setClientYear}
              sameMaxValue={sameMaxValue}
              setSameMaxValue={setSameMaxValue}
              executive={props.executive}
              wip={props.wip}
              sameClientRatio={sameClientRatio}
              setSameClientRatio={setSameClientRatio}
            />
          </div>

          <CompanySummaryCharts
            areaTotalData={areaTotalData}
            regionTotalData={regionTotalData}
            districtTotalData={districtTotalData}
            subareaTotalData={subareaTotalData}
            adaTotalData={adaTotalData}
            areaList={areaList}
            subAreaList={subAreaList}
            sortValue={sortValue}
            orders={orders}
            totalType={totalType}
            setTotalType={setTotalType}
            selectedBranch={selectedBranch}
            setSelectedBranch={setSelectedBranch}
            dataMode={dataMode}
            level={level}
            setLevel={setLevel}
            regionsList={regionsList}
            districtList={districtList}
            labels={labels}
            totalRevenuesBox={{
              company: totalRevenues.company,
              region: { ...totalRevenues.region, ...totalRevenues.district },
              area: totalRevenues.area,
              subarea: totalRevenues.subarea,
              ada: totalRevenues.ada,
            }}
            totalRevenues={totalRevenues}
            totalDataValues={{
              Area: areaTotalData,
              Region: regionTotalData,
              District: districtTotalData,
              Company: totalData,
              Subarea: subareaTotalData,
              ADA: adaTotalData,
            }}
            showClientsRanking={showClientsRanking}
            showBranchClientsRanking={showBranchClientsRanking}
            clientsData={clientsData}
            clientsDetailData={clientsDetailData}
            clientsDataMode={clientsDataMode}
            clientYear={clientYear}
            branchesData={branchesData}
            adaBranchData={adaBranchData}
            revenueData={totalRevenues.branch}
            regionsRawData={regionsRawData}
            sameMaxValue={sameMaxValue}
            pipMilestonesData={milestonesPipData}
            pipData={{
              region: regionsPipData,
              district: districtsPipData,
              branch: branchesPipData,
            }}
            executive={props.executive}
            wip={props.wip}
            sameClientRatio={sameClientRatio}
          />
          {!(showClientsRanking || showBranchClientsRanking) && (
            <CompanyBranchCharts
              dataMode={dataMode}
              branchesData={branchesData}
              adaBranchData={adaBranchData}
              sortOrder={orders.branch}
              sortValue={sortValue}
              totalType={totalType}
              selectedBranch={selectedBranch}
              level={level}
              labels={labels}
              branchesList={branchesList}
              revenueData={
                selectedBranch === "ADA_Branch"
                  ? totalRevenues.ada_branch
                  : totalRevenues.branch
              }
              setTotalType={setTotalType}
              setSelectedBranch={setSelectedBranch}
              setLevel={setLevel}
              sameMaxValue={sameMaxValue}
              rawData={branchesRawData}
              pipMilestonesData={[
                ...milestonesPipData.legacy_branch,
                ...milestonesPipData.new_branch,
              ]}
              branchesPipData={branchesPipData}
              executive={props.executive}
              wip={props.wip}
              goal={props.goal}
            />
          )}
        </div>
      </section>
      <Loader />
    </main>
  );
};
