/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { useEffect, useState } from "react";
import {
  APIFetchMonthlyTopClientsChart,
  APIFetchQuarterlyTopClientsChart,
  APIFetchWeeklyTopClientsChart,
  APIFetchPreviousRolling12MonthsTopClients,
  APIFetchPreviousRolling24MonthsTopClients,
  APIFetchPreviousRolling3MonthsTopClients,
  APIFetchRolling12MonthsTopClients,
  APIFetchRolling3MonthsTopClients,
} from "../api/top-clients";
import { TopClientsFilter } from "../components/modules/topClients/TopClientsFilter";
import { TopClientsTable } from "../components/modules/topClients/TopClientsTable";
import { Loader } from "../components/common/Loader";
import { useDispatch } from "react-redux";
import { setLoading } from "../store/Filter/actions";
import {
  APIFetchMonthlyTopClientsChartAcquisition,
  APIFetchPreviousRolling12MonthsTopClientsAcquisition,
  APIFetchPreviousRolling24MonthsTopClientsAcquisition,
  APIFetchPreviousRolling3MonthsTopClientsAcquisition,
  APIFetchQuarterlyTopClientsChartAcquisition,
  APIFetchRolling12MonthsTopClientsAcquisition,
  APIFetchRolling3MonthsTopClientsAcquisition,
  APIFetchWeeklyTopClientsChartAcquisition,
} from "../api/top-clients-acquisition";
import {
  APIFetchMonthlyTopClientsWip,
  APIFetchQuarterlyTopClientsWip,
  APIFetchRolling12MonthsTopClientsWip,
  APIFetchRolling30DaysTopClientsWip,
  APIFetchRolling3MonthsTopClientsWip,
  APIFetchRolling90DaysTopClientsWip,
  APIFetchWeeklyTopClientsWip,
  APIFetchYTDThisYearTopClientsWip,
} from "../api/top-clients-wip";
import { APIGetADABranches } from "../api/company-summary";

export const TopClients = (props: any) => {
  const dispatch = useDispatch();
  const [selectedTimeline, setSelectedTimeline] = useState("Weekly");
  const [selectedYear, setSelectedYear] = useState<any>("2024");
  const [selectedWeek, setSelectedWeek] = useState<any>();
  const [selectedMonth, setSelectedMonth] = useState<any>();
  const [selectedQuarter, setSelectedQuarter] = useState<any>();
  const [selectedArea, setSelectedArea] = useState("All");
  const [selectedSubarea, setSelectedSubarea] = useState("All");
  const [selectedRegion, setSelectedRegion] = useState("All");
  const [selectedDistrict, setSelectedDistrict] = useState("All");
  const [selectedBranch, setSelectedBranch] = useState("All");
  const [topClientsData, setTopClientsData] = useState<any>([]);
  const [selectedDatamode, setSelectedDatamode] = useState("GrossProfit");
  const [selectedYearMonth, setSelectedYearMonth] = useState<any>({});
  const [exportToggle, setExportToggle] = useState();
  const [acquisitionClient, setAcquisitionClient] = useState(false);
  const [selectedReferenceNote, setSelectedReferenceNote] =
    useState<any>("All");
  const [selectedLevel, setSelectedLevel] = useState("Company");

  useEffect(() => {
    APIGetADABranches();
  }, []);

  const apis: any = {
    Rolling3Months: APIFetchRolling3MonthsTopClients,
    Rolling12Months: APIFetchRolling12MonthsTopClients,
    PreviousRolling3Months: APIFetchPreviousRolling3MonthsTopClients,
    PreviousRolling12Months: APIFetchPreviousRolling12MonthsTopClients,
    PreviousRolling24Months: APIFetchPreviousRolling24MonthsTopClients,
  };

  const apisWip: any = {
    Rolling3Months: APIFetchRolling3MonthsTopClientsWip,
    Rolling12Months: APIFetchRolling12MonthsTopClientsWip,
    PreviousRolling3Months: APIFetchRolling3MonthsTopClientsWip,
    PreviousRolling12Months: APIFetchRolling12MonthsTopClientsWip,
    PreviousRolling24Months: APIFetchRolling12MonthsTopClientsWip,
  };

  const acquisitionApis: any = {
    Rolling3Months: APIFetchRolling3MonthsTopClientsAcquisition,
    Rolling12Months: APIFetchRolling12MonthsTopClientsAcquisition,
    PreviousRolling3Months: APIFetchPreviousRolling3MonthsTopClientsAcquisition,
    PreviousRolling12Months:
      APIFetchPreviousRolling12MonthsTopClientsAcquisition,
    PreviousRolling24Months:
      APIFetchPreviousRolling24MonthsTopClientsAcquisition,
  };

  const chartApis: any = {
    Weekly: APIFetchWeeklyTopClientsChart,
    Monthly: APIFetchMonthlyTopClientsChart,
    Quarterly: APIFetchQuarterlyTopClientsChart,
  };

  const acquisitionChartApis: any = {
    Weekly: APIFetchWeeklyTopClientsChartAcquisition,
    Monthly: APIFetchMonthlyTopClientsChartAcquisition,
    Quarterly: APIFetchQuarterlyTopClientsChartAcquisition,
  };

  // useEffect(() => {
  //   const chartApiList = chartApisWip;
  //   chartApiList[selectedTimeline]?.().then((res: any) => {
  //     setChartData(res.data);
  //   });

  //   setSelectedReferenceNote("All");
  // }, [selectedTimeline]);

  useEffect(() => {
    setSelectedYear(
      selectedTimeline === "YTDLastYear" ||
        selectedTimeline === "LastYearRolling30Days" ||
        selectedTimeline === "LastYearRolling90Days"
        ? "2023"
        : "2024"
    );
  }, [selectedTimeline]);

  // useEffect(() => {
  //   if (props.wip) {
  //     fetchTopClientsData();
  //   } else {
  //     if (acquisitionClient) {
  //       if (selectedReferenceNote !== "All") {
  //         const filteredData = topClientsData.filter(
  //           (data: any) => data.ReferenceNote === selectedReferenceNote
  //         );
  //         setFilteredTopClients(filteredData);
  //       }
  //     }
  //   }
  // }, [selectedReferenceNote]);

  useEffect(() => {
    fetchTopClientsData();
  }, [
    selectedArea,
    selectedSubarea,
    selectedRegion,
    selectedDistrict,
    selectedBranch,
    selectedYear,
    selectedWeek,
    selectedMonth,
    selectedQuarter,
    selectedYearMonth,
    acquisitionClient,
    selectedReferenceNote,
    selectedTimeline,
    selectedLevel,
  ]);

  useEffect(() => {
    setSelectedReferenceNote("All");
  }, [acquisitionClient]);

  const fetchTopClientsData = async () => {
    if (!selectedTimeline || !selectedYear) return;
    switch (selectedTimeline) {
      case "Weekly":
        if (selectedWeek) {
          dispatch(setLoading(true));

          const { data } = await APIFetchWeeklyTopClientsWip(
            selectedWeek,
            getSelectedReferenceNote(),
            getSelectedLocation(),
            selectedLevel
          );
          // acquisitionClient
          // ? await APIFetchWeeklyTopClientsAcquisition(
          //     selectedWeek,
          //     getSelectedLocation()
          //   )
          // : await APIFetchWeeklyTopClients(
          //     selectedWeek,
          //     getSelectedLocation()
          //   );
          data.forEach((d: any) => {
            d.GPRankSlot = d.GPPreviousRank - d.GPThisRank;
            d.RevRankSlot = d.RevPreviousRank - d.RevThisRank;
          });
          setTopClientsData([...data]);
          dispatch(setLoading(false));
        }
        break;
      case "Monthly":
        if (selectedMonth) {
          dispatch(setLoading(true));
          const { data } = await APIFetchMonthlyTopClientsWip(
            selectedYear + selectedMonth,
            getSelectedReferenceNote(),
            getSelectedLocation(),
            selectedLevel
          );
          // const { data } = acquisitionClient
          //   ? await APIFetchMonthlyTopClientsAcquisition(
          //       selectedYear + selectedMonth,
          //       getSelectedLocation()
          //     )
          //   : await APIFetchMonthlyTopClients(
          //       selectedYear + selectedMonth,
          //       getSelectedLocation()
          //     );
          data.forEach((d: any) => {
            d.GPRankSlot = d.GPPreviousRank - d.GPThisRank;
            d.RevRankSlot = d.RevPreviousRank - d.RevThisRank;
          });
          setTopClientsData([...data]);
          dispatch(setLoading(false));
        }
        break;
      case "Quarterly":
        if (selectedQuarter) {
          dispatch(setLoading(true));
          const { data } = await APIFetchQuarterlyTopClientsWip(
            selectedYear + selectedQuarter,
            getSelectedReferenceNote(),
            getSelectedLocation(),
            selectedLevel
          );
          // const { data } = acquisitionClient
          //   ? await APIFetchQuarterlyTopClientsAcquisition(
          //       selectedYear + selectedQuarter,
          //       getSelectedLocation()
          //     )
          //   : await APIFetchQuarterlyTopClients(
          //       selectedYear + selectedQuarter,
          //       getSelectedLocation()
          //     );
          data.forEach((d: any) => {
            d.GPRankSlot = d.GPPreviousRank - d.GPThisRank;
            d.RevRankSlot = d.RevPreviousRank - d.RevThisRank;
          });
          setTopClientsData([...data]);
          dispatch(setLoading(false));
        }
        break;
      case "Rolling30Days":
      case "LastYearRolling30Days":
        if (selectedWeek) {
          dispatch(setLoading(true));
          const { data } = await APIFetchRolling30DaysTopClientsWip(
            selectedWeek,
            getSelectedReferenceNote(),
            getSelectedLocation(),
            selectedLevel
          );
          // const { data } = acquisitionClient
          //   ? await APIFetchRolling30DaysTopClientsAcquisition(
          //       selectedWeek,
          //       getSelectedLocation()
          //     )
          //   : await APIFetchRolling30DaysTopClients(
          //       selectedWeek,
          //       getSelectedLocation()
          //     );
          data.forEach((d: any) => {
            d.GPRankSlot = d.GPPreviousRank - d.GPThisRank;
            d.RevRankSlot = d.RevPreviousRank - d.RevThisRank;
          });
          setTopClientsData([...data]);
          dispatch(setLoading(false));
        }
        break;
      case "Rolling90Days":
      case "LastYearRolling90Days":
        if (selectedWeek) {
          dispatch(setLoading(true));
          const { data } = await APIFetchRolling90DaysTopClientsWip(
            selectedWeek,
            getSelectedReferenceNote(),
            getSelectedLocation(),
            selectedLevel
          );
          // const { data } = acquisitionClient
          //   ? await APIFetchRolling90DaysTopClientsAcquisition(
          //       selectedWeek,
          //       getSelectedLocation()
          //     )
          //   : await APIFetchRolling90DaysTopClients(
          //       selectedWeek,
          //       getSelectedLocation()
          //     );
          data.forEach((d: any) => {
            d.GPRankSlot = d.GPPreviousRank - d.GPThisRank;
            d.RevRankSlot = d.RevPreviousRank - d.RevThisRank;
          });
          setTopClientsData([...data]);
          dispatch(setLoading(false));
        }
        break;
      case "YTD":
      case "YTDLastYear":
        if (selectedWeek) {
          dispatch(setLoading(true));
          const { data } = await APIFetchYTDThisYearTopClientsWip(
            selectedWeek,
            getSelectedReferenceNote(),
            getSelectedLocation(),
            selectedLevel
          );
          // acquisitionClient
          //   ? await APIFetchYTDThisYearTopClientsAcquisition(
          //       selectedWeek,
          //       getSelectedLocation()
          //     )
          //   : await APIFetchYTDThisYear(selectedWeek, getSelectedLocation());
          data.forEach((d: any) => {
            d.GPRankSlot = d.GPPreviousRank - d.GPThisRank;
            d.RevRankSlot = d.RevPreviousRank - d.RevThisRank;
          });
          setTopClientsData([...data]);
          dispatch(setLoading(false));
        }
        break;

      default:
        if (selectedYearMonth[selectedTimeline]) {
          dispatch(setLoading(true));
          // const apiList = acquisitionClient ? acquisitionApis : apis;
          const apiList = apisWip;
          const { data } = await apiList[selectedTimeline](
            selectedYearMonth[selectedTimeline].year,
            selectedYearMonth[selectedTimeline].month,
            getSelectedReferenceNote(),
            getSelectedLocation(),
            selectedLevel
          );
          data.forEach((d: any) => {
            d.GPRankSlot = d.GPPreviousRank - d.GPThisRank;
            d.RevRankSlot = d.RevPreviousRank - d.RevThisRank;
          });
          setTopClientsData([...data]);
          dispatch(setLoading(false));
        }
        break;
    }
  };

  const getSelectedLocation = () => {
    return selectedBranch === "All"
      ? selectedDistrict === "All"
        ? selectedRegion === "All"
          ? selectedSubarea === "All"
            ? selectedArea === "All"
              ? "All"
              : selectedArea
            : selectedSubarea
          : selectedRegion
        : selectedDistrict
      : selectedBranch;
  };

  const getSelectedReferenceNote = () => {
    return acquisitionClient ? selectedReferenceNote : "";
  };
  const companyLevels = [
    { name: "ADASTAFF, Inc.", level: "ADA" },
    { name: "Ascend Staffing", level: "Company" },
  ];

  return (
    <div className="flex flex-col overflow-hidden h-[100dvh] gap-2 p-5 pt-2">
      <Loader />
      <div className=" w-full flex justify-center items-center gap-4 -ml-12">
        {companyLevels.map((v: any) => {
          return (
            <div
              className={`border-[1px] rounded-lg p-2 px-4 text-xs   cursor-pointer shadow-md  ${
                selectedLevel === v.level
                  ? "bg-[#3EA333] text-white"
                  : "hover:bg-[#ebf0f2]"
              }`}
              onClick={() => {
                setSelectedLevel(v.level);
                setSelectedBranch("All");
                setSelectedArea("All");
                setSelectedSubarea("All");
                setSelectedRegion("All");
                setSelectedDistrict("All");
              }}
            >
              {v.name}
            </div>
          );
        })}
      </div>
      <TopClientsFilter
        selectedLevel={selectedLevel}
        selectedYear={selectedYear}
        setSelectedYear={setSelectedYear}
        selectedWeek={selectedWeek}
        setSelectedWeek={setSelectedWeek}
        selectedMonth={selectedMonth}
        setSelectedMonth={setSelectedMonth}
        selectedQuarter={selectedQuarter}
        setSelectedQuarter={setSelectedQuarter}
        selectedArea={selectedArea}
        setSelectedArea={setSelectedArea}
        selectedSubarea={selectedSubarea}
        setSelectedSubarea={setSelectedSubarea}
        selectedRegion={selectedRegion}
        setSelectedRegion={setSelectedRegion}
        selectedDistrict={selectedDistrict}
        setSelectedDistrict={setSelectedDistrict}
        selectedBranch={selectedBranch}
        setSelectedBranch={setSelectedBranch}
        selectedTimeline={selectedTimeline}
        setSelectedTimeline={setSelectedTimeline}
        selectedDatamode={selectedDatamode}
        setSelectedDatamode={setSelectedDatamode}
        selectedYearMonth={selectedYearMonth}
        setSelectedYearMonth={setSelectedYearMonth}
        exportData={setExportToggle}
        wip={props.wip}
        acquisitionClient={acquisitionClient}
        setAcquisitionClient={setAcquisitionClient}
        selectedReferenceNote={selectedReferenceNote}
        setSelectedReferenceNote={setSelectedReferenceNote}
      />
      <TopClientsTable
        data={topClientsData}
        selectedDatamode={selectedDatamode}
        selectedTimeline={selectedTimeline}
        exportData={exportToggle}
        wip={props.wip}
        selectedLevel={selectedLevel}
      />
    </div>
  );
};
