/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { useEffect, useState } from "react";
import { CompanySummarySidebar } from "../components/modules/companySummary/CompanySummarySidebar";
import "../assets/scss/PipSummary.scss";
import PipSummaryBranchChart from "../components/modules/pipSummary/pipSummaryBranchChart";
import { useDispatch, useSelector } from "react-redux";
import { getCalendarWeekLabels, setLoading } from "../store/Filter/actions";
import { ActionIcon, Switch } from "@mantine/core";
import { Loader } from "../components/common/Loader";
import { loadPipSummaryData } from "../store/PipSummary/actions";

export const PipSummaryReport = (props: any) => {
  const dispatch = useDispatch();
  const milestonesData: {
    region: [];
    district: [];
    legacy_branch: [];
    new_branch: [];
  } = useSelector((state: any) => state.pipSummaryReducer.milestonesData);

  const branchesData = useSelector(
    (state: any) => state.pipSummaryReducer.branchesData
  );
  const regionsData = useSelector(
    (state: any) => state.pipSummaryReducer.regionsData
  );
  const districtsData = useSelector(
    (state: any) => state.pipSummaryReducer.districtsData
  );
  const [enableTooltip, setEnableTooltip] = useState(true);

  const getWeekDates = async () => {
    dispatch(await getCalendarWeekLabels("2024"));
  };

  const accessibleRegion = useSelector(
    (state: any) => state.pipSummaryReducer.pipRegions
  );

  useEffect(() => {
    document.title = "PIP Summary Report";
    (async () => {
      dispatch(setLoading(true));
      await getWeekDates();
      if (accessibleRegion.length > 0)
        dispatch(
          loadPipSummaryData(props.executive, accessibleRegion.join(","))
        );
      dispatch(setLoading(false));
    })();
  }, [accessibleRegion]);

  const getPipRegions = () => {};

  return (
    <div className="bg-primary h-[100vh] flex overflow-hidden pip-summary">
      <CompanySummarySidebar executive={props.executive} />
      <div className="flex-1 ml-1 pt-2 overflow-y-auto">
        <div className="absolute top-0 left-0 flex items-center">
          <ActionIcon
            onClick={() => {
              setEnableTooltip(true);
            }}
          >
            <i className="material-icons" style={{ fontSize: "32px" }}>
              chevron_left
            </i>
          </ActionIcon>
          <ActionIcon>
            <a
              href="https://app.powerbi.com/groups/d9341e4a-2be0-4eb6-8eb1-5a9ed297ac2a/reports/60da792a-d41f-48b5-a002-80366a29f241/ReportSectionfdcec90ba28704f33c71?experience=power-bi"
              target={"_blank"}
              rel="noreferrer"
            >
              <i className="text-slate-600 material-icons text-[28px]">home</i>
            </a>
          </ActionIcon>
        </div>
        <div className="flex justify-center relative ">
          <span className="h-12 px-16 sm:px-20 bg-[#018A80] flex items-center font-bold text-white text-xs text-center sm:max-lg:text-sm lg:text-md">
            PIP Summary Report
          </span>
          <div className="flex flex-row sm:flex-col w-[100%] flex-wrap gap-2 sm:gap-1 text-sm font-bold absolute top-14 sm:top-0 sm:mr-5 right-0 items-end ">
            {/* <div className="flex flex-col gap-1 text-sm font-bold absolute right-0 mr-5 items-end"> */}
            <div className="flex items-center justify-end gap-1 lg:gap-5 text-xs lg:text-sm">
              Region:{" "}
              <span className="h-3 sm:h-5 w-10 lg:w-20 bg-[#285680]"></span>
            </div>
            <div className="flex items-center justify-end gap-1 lg:gap-5 text-xs lg:text-sm">
              District:{" "}
              <span className="h-3 sm:h-5 w-10 lg:w-20 bg-cyan-400"></span>
            </div>
            <div className="flex items-center justify-end gap-1 lg:gap-5 text-xs lg:text-sm">
              New Branch Manager:{" "}
              <span className="h-3 sm:h-5 w-10 lg:w-20 bg-[#00B85F]"></span>
            </div>
            <div className="flex items-center justify-end gap-1 lg:gap-5 text-xs lg:text-sm">
              Legacy Branch:{" "}
              <span className="h-3 sm:h-5 w-10 lg:w-20 bg-tertiary"></span>
            </div>
            <div className="flex items-center justify-end gap-1 lg:gap-5 text-xs lg:text-sm">
              90 Days On PIP:{" "}
              <span className="h-3 sm:h-5 w-10 lg:w-20 bg-[#5696c3]"></span>
            </div>
            <Switch
              size="xs"
              label="Show Tooltip for Chart"
              checked={enableTooltip}
              onChange={(event) =>
                setEnableTooltip(event.currentTarget.checked)
              }
            />
          </div>
        </div>
        {milestonesData && (
          <div>
            <div
              className={`pip-summary-charts flex flex-wrap items-end mt-32 sm:mt-24 ${
                (milestonesData.region && milestonesData.region?.length != 0) ||
                (milestonesData.district &&
                  milestonesData.district?.length != 0)
                  ? "border-b-8 border-slate-400"
                  : ""
              } gap-[1%]`}
            >
              {milestonesData.region?.map((regionMilestone: any) => {
                return (
                  <PipSummaryBranchChart
                    executive={props.executive}
                    branchData={regionsData[regionMilestone.Location]}
                    branchMilestone={regionMilestone}
                    region="true"
                    enableTooltip={enableTooltip}
                    key={regionMilestone.Location}
                    locationType="Region"
                  />
                );
              })}
              {milestonesData.district?.map((districtMilestone: any) => {
                return (
                  <PipSummaryBranchChart
                    executive={props.executive}
                    branchData={districtsData[districtMilestone.Location]}
                    branchMilestone={districtMilestone}
                    district="true"
                    enableTooltip={enableTooltip}
                    key={districtMilestone.Location}
                    locationType="District"
                  />
                );
              })}
            </div>

            <div
              className={`branch-charts flex flex-wrap items-end  ${
                milestonesData.legacy_branch &&
                milestonesData.legacy_branch?.length != 0
                  ? "border-b-8 border-slate-400 mt-10"
                  : ""
              } gap-[1%]`}
            >
              {milestonesData.legacy_branch?.map((branchMilestone: any) => {
                return (
                  <PipSummaryBranchChart
                    executive={props.executive}
                    branchData={branchesData[branchMilestone.Location]}
                    branchMilestone={branchMilestone}
                    legacy="true"
                    enableTooltip={enableTooltip}
                    key={branchMilestone.Location}
                    locationType="Branch"
                  />
                );
              })}
            </div>

            <div className="branch-charts flex flex-wrap items-end mt-10 gap-[1%]">
              {milestonesData.new_branch?.map((branchMilestone: any) => {
                return (
                  <PipSummaryBranchChart
                    executive={props.executive}
                    branchData={branchesData[branchMilestone.Location]}
                    branchMilestone={branchMilestone}
                    enableTooltip={enableTooltip}
                    key={branchMilestone.Location}
                    locationType="Branch"
                  />
                );
              })}
            </div>
          </div>
        )}
      </div>
      <Loader />
    </div>
  );
};
