/*
 * Copyright (C) 2023 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import logo from "../assets/images/logo.png";
import { Link } from "react-router-dom";
import '../assets/scss/Landing.scss';

export const Landing = ({ loading, reports }: any) => {

    return loading ? <>LOADING...</> : <main>
        <section className={"landing-screen"}>
            <div className="logo-area">
                <img src={logo} alt="" style={{ aspectRatio: "2382 / 880", width: "60%" }} />
            </div>
            <div className="menu-area">
                <div className="menu-items">
                    {
                        reports.map((v: any, key: any) => (
                            <div className={"flex align-center"} key={key}>
                                <img src={require(`../assets/images/${v.ReportIconName}.svg`)} alt="" height={30} width={30}
                                    style={{ objectFit: "contain", objectPosition: "center", marginRight: "4px" }} />
                                <Link className="landing-menu" to={v.ReportName}>{v.ReportDisplayName}</Link>
                            </div>
                        ))
                    }
                </div>
            </div>
        </section>
    </main>
}