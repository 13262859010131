/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

export const getWeekData = (data: any, type = "Revenue") => {
  let dataArr: any = [];
  let arr: any = {};
  data?.forEach((v: any) => {
    arr = {
      ...arr,
      [v.CalendarWeekNumber]: v[type],
    };
  });
  for (let i = 1; i <= 52; i++) {
    dataArr = [...dataArr, arr[i] ? arr[i] : null];
  }
  return dataArr;
};

export const getWeekDataForClients = (
  data: any[],
  type: "Revenue",
  key: any,
  weekNumbers: []
): number[] => {
  const weekData: any = {};
  data.forEach((item) => {
    weekData[item.CalendarWeekNumber] = item[type];
  });
  const resultArray: number[] = weekNumbers.map((week) => {
    return weekData[week] !== undefined ? weekData[week] : 0;
  });
  return resultArray;
};

// export const getWeekDataForClients = (
//   data: any,
//   type = "Revenue",
//   key: any
// ) => {
//   let dataArr: any = [];
//   let arr: any = {};
//   // console.log(data, "data");
//   data?.forEach((v: any) => {
//     arr = {
//       ...arr,
//       [v.CalendarWeekNumber]: v[type],
//     };
//   });
//   for (let i = 1; i <=52 ; i++) {
//     dataArr = [
//       ...dataArr,
//       arr[i]
//         ? arr[i]
//         : key === "2024"
//         ? i < moment(new Date()).get("W") - 1
//           ? 0
//           : null
//         : 0,
//     ];
//   }
//   // console.log(dataArr, "DataArr");
//   return dataArr;
// };

export const getMonthData = (data: any, type = "Revenue") => {
  let dataArr: any = [];
  let arr: any = {};
  data?.forEach((v: any) => {
    arr = {
      ...arr,
      [v.CalendarMonthNumber]: v[type],
    };
  });
  for (let i = 1; i <= 12; i++) {
    dataArr = [...dataArr, arr[i] ? arr[i] : null];
  }
  return dataArr;
};

export const getQuarterData = (data: any, type = "Revenue") => {
  let dataArr: any = [];
  let arr: any = {};
  data?.forEach((v: any) => {
    arr = {
      ...arr,
      [v.CalendarQuarter]: v[type],
    };
  });
  for (let i = 1; i <= 4; i++) {
    dataArr = [...dataArr, arr[i] ? arr[i] : null];
  }
  return dataArr;
};

export const convertDaysIntoDuration = (data: number) => {
  const years = Math.floor(data / 365);
  const months = Math.floor((data % 365) / 30);
  const days = (data % 365) % 30;

  return (
    (years !== 0 ? years + ` ${years === 1 ? "Y" : "Y"} ` : "") +
    (months !== 0 ? months + ` ${months === 1 ? "M" : "M"} ` : "") +
    (days !== 0 ? days + ` ${days === 1 ? "D" : "D"} ` : "")
  );
};

export const convertDaysIntoDurationLong = (data: number) => {
  const years = Math.floor(data / 365);
  const months = Math.floor((data % 365) / 30);
  const days = (data % 365) % 30;
  return (
    (years !== 0 ? years + ` ${years === 1 ? "Year" : "Years"} ` : "") +
    (months !== 0 ? months + ` ${months === 1 ? "Month" : "Months"} ` : "") +
    (days !== 0 ? days + ` ${days === 1 ? "Day" : "days"} ` : "")
  );
};

export const getPipWeekData = (data: any, type = "Revenue") => {
  let dataArr: any = [];
  let arr: any = {};
  data?.forEach((v: any) => {
    arr = {
      ...arr,
      [v.CalendarWeekNumber]: v[type],
    };
  });
  for (let i = 12; i <= 52; i++) {
    dataArr = [...dataArr, arr[i] ? arr[i] : null];
  }
  return dataArr;
};
