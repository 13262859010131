/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

export const GET_PIP_SUMMARY_DATA = "GET_PIP_SUMMARY_DATA";
export const SET_PIP_MILESTONE_DATA = "SET_PIP_MILESTONE_DATA";
export const SET_PIP_REGION_DATA = "SET_PIP_REGION_DATA";
export const SET_PIP_DISTRICT_DATA = "GET_PIP_DISTRICT_DATA";
export const SET_PIP_BRANCH_DATA = "SET_PIP_BRANCH_DATA";
export const SET_PIP_REGIONS = "SET_PIP_REGIONS";
