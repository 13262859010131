/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import SummaryChart from "./SummaryChart";
import { sortByKeyCompanySummary } from "../../../utils/helper/cellFormatter";
import ClientSummaryChart from "./ClientSummaryChart";
import { convertDaysIntoDuration } from "./utils/weekdata";
import SummaryChartBranch from "./SummaryChartBranch";
import { useEffect, useState } from "react";
import { APIGetCalendarWeeks3Months } from "../../../api/filter";

export const CompanySummaryCharts = (props: any) => {
  const [weeksClientData, setWeeksClientData] = useState([]);
  const regions = props.regionsList
    .map((r: any) => r.Region)
    ?.filter((d: any) => d !== null);
  const regionsData = props.regionsRawData?.filter((d: any) => {
    return regions.includes(d.Location);
  });
  const gp: any =
    regionsData?.map((d: any) => {
      return d.GrossProfit;
    }) || [];
  const rev: any =
    regionsData?.map((d: any) => {
      return d.Revenue;
    }) || [];
  // const maxGp = getMaxRoundedValue(Math.max(...gp));
  // const maxRev = getMaxRoundedValue(Math.max(...rev));
  // const minGp = getMinRoundedValue(Math.min(...gp));
  // const minRev = getMinRoundedValue(Math.min(...rev));
  const getRevenue = (val: any) => {
    return val?.length > 0 ? val[0] : null;
  };

  useEffect(() => {
    APIGetCalendarWeeks3Months().then((res: any) =>
      setWeeksClientData(res.data)
    );
  }, []);

  let selectedBranchData = {};
  let selectedBranchManagers: any = [];
  let selectedBranchSalesManagerDays = "";
  let selectedBranchManagerDays = "";
  if (props.showBranchClientsRanking) {
    selectedBranchData =
      props.level === "ADA"
        ? props.adaBranchData[props.selectedBranch]
        : props.branchesData[props.selectedBranch];
    selectedBranchManagers =
      Object.values(selectedBranchData).length > 0
        ? Object.values(selectedBranchData)[0]
        : [];
    selectedBranchSalesManagerDays = convertDaysIntoDuration(
      selectedBranchManagers[0]?.BranchManagerDays ?? ""
    );
    selectedBranchManagerDays = convertDaysIntoDuration(
      selectedBranchManagers[0]?.SalesManagerDays ?? ""
    );
  }

  const getMilestonesData = (location: any) => {
    return (
      [
        ...props.pipMilestonesData.region,
        ...props.pipMilestonesData.district,
        ...props.pipMilestonesData.legacy_branch,
        ...props.pipMilestonesData.new_branch,
      ].find((d) => d.Location === location) ?? null
    );
  };

  return (
    <section className="cs-summary-charts">
      <div className="cs-company-charts">
        {!props.showBranchClientsRanking &&
          props.selectedBranch === "Company" && (
            <div className={"summary-chart-box "}>
              <div>
                <SummaryChart
                  sortValue={props.sortValue}
                  labels={props.labels}
                  dataMode={props.dataMode}
                  name={{ label: "Company" }}
                  data={props.totalDataValues.Company}
                  manager={""}
                  revenue={getRevenue([props.totalRevenues?.company])}
                  setSelectedBranch={props.setSelectedBranch}
                  setLevel={props.setLevel}
                  setTotalType={props.setTotalType}
                  location={{
                    type: "Company",
                    name: "Company",
                    level: "Company",
                  }}
                  executive={props.executive}
                  wip={props.wip}
                />
              </div>
            </div>
          )}
        {!props.showBranchClientsRanking && props.level !== "Company" && (
          <div className={"summary-chart-box flex align-end total-chart"}>
            <SummaryChart
              sortValue={props.sortValue}
              labels={props.labels}
              dataMode={props.dataMode}
              name={{ label: props.totalType + " Total" }}
              data={
                props.totalDataValues?.[props.level]?.[props.selectedBranch] ??
                {}
              }
              manager={
                props.totalDataValues?.[props.level]?.[props.selectedBranch]?.[
                  props.labels[2]
                ]?.length > 0
                  ? props.totalDataValues?.[props.level]?.[
                      props.selectedBranch
                    ]?.[props.labels[2]]?.[0]?.ManagerName
                  : props.totalDataValues?.[props.level]?.[
                      props.selectedBranch
                    ]?.[props.labels[1]]?.[0]?.ManagerName
              }
              revenue={getRevenue(
                props.level === "ADA"
                  ? props.totalRevenuesBox?.[props.level.toLowerCase()]
                  : props.totalRevenuesBox?.[props.level.toLowerCase()]?.[
                      props.selectedBranch
                    ]
              )}
              setSelectedBranch={props.setSelectedBranch}
              setLevel={props.setLevel}
              setTotalType={props.setTotalType}
              location={{
                type: props.totalType,
                name: props.selectedBranch,
                level: props.level,
              }}
              pipMilestonesData={getMilestonesData(props.selectedBranch)}
              pipData={
                props.pipData?.[props.level.toLowerCase()]?.[
                  props.selectedBranch
                ]
              }
              executive={props.executive}
              wip={props.wip}
            />
          </div>
        )}
        {!(props.showClientsRanking || props.showBranchClientsRanking) &&
        Object.keys(props.areaTotalData).length > 0 &&
        props.selectedBranch === "Company"
          ? sortByKeyCompanySummary(
              Object.values(props.totalRevenues.area as any[]).flat(),
              props.sortValue
            ).map((v: any, key: number) => {
              const data: any = props.areaTotalData[v?.AreaName];
              if (!!data) {
                const labelTemp = Object.keys(data);
                return (
                  <div className={"summary-chart-box"}>
                    <SummaryChart
                      sortValue={props.sortValue}
                      labels={props.labels}
                      dataMode={props.dataMode}
                      name={{ label: v?.AreaName ?? "" }}
                      data={data}
                      manager={data[labelTemp[0]][0].ManagerName}
                      revenue={getRevenue(
                        props.totalRevenues?.area[v?.AreaName]
                      )}
                      setSelectedBranch={props.setSelectedBranch}
                      setLevel={props.setLevel}
                      setTotalType={props.setTotalType}
                      location={{
                        type: "Area",
                        name: v.AreaName,
                        level: "Area",
                      }}
                      sameMaxValue={props.sameMaxValue}
                      executive={props.executive}
                      wip={props.wip}
                    />
                  </div>
                );
              } else {
                return "";
              }
            })
          : ""}
        {!(props.showClientsRanking || props.showBranchClientsRanking) &&
        Object.keys(props.subareaTotalData).length > 0 &&
        ["Area", "Company"].includes(props.level)
          ? sortByKeyCompanySummary(
              Object.values(props.totalRevenues.subarea as any[]).flat(),
              props.sortValue
            ).map((v: any, key: number) => {
              const data: any = props.subareaTotalData[v["Sub Area Name"]];
              if (!!data && props.subAreaList?.includes(v["Sub Area Name"])) {
                const labelTemp = Object.keys(data);
                return (
                  <div
                    className={"summary-chart-box "}
                    key={"subareaname" + key}
                  >
                    <SummaryChart
                      sortValue={props.sortValue}
                      labels={props.labels}
                      dataMode={props.dataMode}
                      name={{ label: v["Sub Area Name"] ?? "" }}
                      data={data}
                      location={{
                        type: "VP",
                        name: v["Sub Area Name"],
                        level: "Subarea",
                      }}
                      manager={data[labelTemp[0]][0].ManagerName}
                      revenue={getRevenue(
                        props.totalRevenues?.subarea[v["Sub Area Name"]]
                      )}
                      setSelectedBranch={props.setSelectedBranch}
                      setLevel={props.setLevel}
                      setTotalType={props.setTotalType}
                      executive={props.executive}
                      wip={props.wip}
                    />
                  </div>
                );
              } else {
                return "";
              }
            })
          : ""}
        {!(props.showClientsRanking || props.showBranchClientsRanking) &&
        Object.keys({ ...props.regionTotalData }).length > 0 &&
        props.level !== "Region" &&
        props.level !== "District" &&
        props.level !== "ADA"
          ? sortByKeyCompanySummary(
              Object.values({ ...props.totalRevenues.region } as any[]).flat(),
              props.sortValue
            ).map((v: any, key) => {
              const label = v?.Region;
              const data: any = props.regionTotalData[label];
              const region = props.regionsList.find((v: any) => {
                return v.Region === label;
              });

              if (!!data && region) {
                const labelTemp = Object.keys(data);
                return (
                  <div className={"summary-chart-box"} key={"region" + key}>
                    <SummaryChart
                      sortValue={props.sortValue}
                      labels={props.labels}
                      dataMode={props.dataMode}
                      name={{ label: label }}
                      data={data}
                      manager={data[labelTemp[0]][0].ManagerName}
                      revenue={getRevenue(
                        {
                          ...props.totalRevenues.region,
                          // ...props.totalRevenues.district,
                        }[label]
                      )}
                      setSelectedBranch={props.setSelectedBranch}
                      setLevel={props.setLevel}
                      setTotalType={props.setTotalType}
                      location={{
                        type: "Region",
                        name: label,
                        level: "Region",
                      }}
                      sameMaxValue={props.sameMaxValue}
                      pipMilestonesData={getMilestonesData(label)}
                      pipData={
                        {
                          ...props.pipData?.region,
                          // ...props.pipData?.district,
                        }?.[label]
                      }
                      executive={props.executive}
                      wip={props.wip}
                    />
                  </div>
                );
              } else {
                return "";
              }
            })
          : ""}
        {/* {!(props.showClientsRanking || props.showBranchClientsRanking) &&
        Object.keys({ ...props.regionTotalData }).length > 0 &&
        props.level !== "Region"
          ? sortByKeyCompanySummary(
              Object.values({
                ...props.totalRevenues.district,
              } as any[]).flat(),
              props.sortValue
            ).map((v: any, key) => {
              const label = v?.Region ?? v?.District;
              const data: any = props.regionTotalData[label];
              const region = props.regionsList.find((v: any) => {
                return v.Location === label;
              });

              if (!!data && region) {
                const labelTemp = Object.keys(data);
                return (
                  <div className={"summary-chart-box"} key={"region" + key}>
                    <SummaryChart
                      sortValue={props.sortValue}
                      labels={props.labels}
                      dataMode={props.dataMode}
                      name={{ label: label }}
                      data={data}
                      manager={
                        data[labelTemp[0]]?.length > 0
                          ? data[labelTemp[0]][0].ManagerName
                          : ""
                      }
                      revenue={getRevenue(
                        {
                          ...props.totalRevenues.region,
                          ...props.totalRevenues.district,
                        }[label]
                      )}
                      setSelectedBranch={props.setSelectedBranch}
                      setLevel={props.setLevel}
                      setTotalType={props.setTotalType}
                      location={{
                        type: region.Title,
                        name: label,
                        level: "Region",
                      }}
                      sameMaxValue={props.sameMaxValue}
                      pipMilestonesData={getMilestonesData(label)}
                      pipData={
                        {
                          ...props.pipData?.region,
                          ...props.pipData?.district,
                        }?.[label]
                      }
                      executive={props.executive}
                      wip={props.wip}
                    />
                  </div>
                );
              } else {
                return "";
              }
            })
          : ""} */}
        {!(props.showClientsRanking || props.showBranchClientsRanking) &&
        Object.keys({ ...props.districtTotalData }).length > 0 &&
        props.level !== "District" &&
        props.level !== "ADA"
          ? sortByKeyCompanySummary(
              Object.values({
                ...props.totalRevenues.district,
              } as any[]).flat(),
              props.sortValue
            ).map((v: any, key) => {
              const label = v?.District;
              const data: any = props.districtTotalData[label];
              const district = props.districtList.find((v: any) => {
                return v.District === label;
              });

              if (!!data && district) {
                const labelTemp = Object.keys(data);
                return (
                  <div className={"summary-chart-box"} key={"district" + key}>
                    <SummaryChart
                      sortValue={props.sortValue}
                      labels={props.labels}
                      dataMode={props.dataMode}
                      name={{ label: label }}
                      data={data}
                      manager={
                        data[labelTemp[0]]?.length > 0
                          ? data[labelTemp[0]][0].ManagerName
                          : ""
                      }
                      revenue={getRevenue(
                        {
                          // ...props.totalRevenues.district,
                          ...props.totalRevenues.district,
                        }[label]
                      )}
                      setSelectedBranch={props.setSelectedBranch}
                      setLevel={props.setLevel}
                      setTotalType={props.setTotalType}
                      location={{
                        type: "Area Branch",
                        name: label,
                        level: "District",
                      }}
                      sameMaxValue={props.sameMaxValue}
                      pipMilestonesData={getMilestonesData(label)}
                      pipData={
                        {
                          ...props.pipData?.district,
                          ...props.pipData?.district,
                        }?.[label]
                      }
                      executive={props.executive}
                      wip={props.wip}
                    />
                  </div>
                );
              } else {
                return "";
              }
            })
          : ""}

        {props.showBranchClientsRanking && (
          <div className={"summary-chart-box"}>
            <SummaryChartBranch
              name={{ label: props.selectedBranch ?? "" }}
              data={
                props.level === "ADA"
                  ? props.adaBranchData[props.selectedBranch]
                  : props.branchesData[props.selectedBranch]
              }
              manager={selectedBranchManagers[0]?.BranchManager ?? "VACANT"}
              dataMode={props.dataMode}
              branchManagerDays={selectedBranchSalesManagerDays}
              labels={props.labels}
              salesManagerDays={selectedBranchManagerDays}
              sortValue={props.sortValue}
              salesManager={selectedBranchManagers[0]?.SalesManager ?? "VACANT"}
              revenue={getRevenue(
                props.level === "ADA"
                  ? props.totalRevenues.ada_branch[props.selectedBranch]
                  : props.totalRevenues.branch[props.selectedBranch]
              )}
              location={{
                name: props.selectedBranch ?? "",
                type: props.level === "ADA" ? "ADA_Branch" : "Branch",
                level: props.level === "ADA" ? props.level : "Branch",
              }}
              pipMilestonesData={getMilestonesData(props.selectedBranch)}
              pipData={props.pipData?.branch?.[props.selectedBranch]}
              executive={props.executive}
              wip={props.wip}
            />
          </div>
        )}

        {(props.showClientsRanking || props.showBranchClientsRanking) &&
          props.clientsData?.length > 0 &&
          props.clientsDetailData &&
          props.clientsData.map((client: any, key: any) => {
            return (
              <div className={"summary-chart-box"} key={"client" + key}>
                <ClientSummaryChart
                  weeksClientData={weeksClientData}
                  name={{ label: client.ClientName, rank: key + 1 }}
                  dataMode={props.clientsDataMode}
                  data={props.clientsDetailData[client.ClientName]}
                  setSelectedBranch={props.setSelectedBranch}
                  setLevel={props.setLevel}
                  setTotalType={props.setTotalType}
                  clientYear={props.clientYear}
                  sameClientRatio={props.sameClientRatio}
                  totalData={
                    props.selectedBranch === "Company"
                      ? props.totalRevenues.company
                      : props.totalRevenues?.[props.level.toLowerCase()]?.[
                          props.selectedBranch
                        ][0]
                  }
                />
              </div>
            );
          })}
      </div>
    </section>
  );
};
