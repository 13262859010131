/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */
import ReactApexChart from "react-apexcharts";
import { Component, useEffect, useState } from "react";
import { getWeekData } from "./utils/weekdata";
import {
  formatAmount,
  millionConverter,
  percentConverterFixed,
} from "../../../utils/helper/cellFormatter";
import {
  dottedLine,
  formatPercChange,
  labelFormatter,
} from "../../../utils/helper/charts";
import { useDispatch, useSelector } from "react-redux";
import {
  setEnableTooltip,
  setShowBranchClientsRanking,
  setShowPipSummaryLines,
} from "../../../store/CompanySummary/actions";
import CompanySummaryNotes from "./CompanySummaryNotes";
import { getMaxRoundedValue, getMinRoundedValue } from "./utils/operations";
import PipSummaryNotes from "../pipSummary/PipSummaryNotes";
import { useDisclosure } from "@mantine/hooks";
import { Modal, Popover } from "@mantine/core";
import { groupByKey } from "../../../utils/group";

const initialColors = {
  Revenue: {
    InExp: [
      { label: "Previous Year", color: "#1875B5" },
      { label: "Last Year", color: "#FF7F0E" },
      { label: "Current Year", color: "#29A229" },
      { label: "Actual Goal", color: "#A66999" },
      { label: "12 Months", color: "#689FB0" },
    ],
    Exp: [
      { label: "Previous Year", color: "#1875B5" },
      { label: "Last Year", color: "#FF7F0E" },
      { label: "Current Year", color: "#29A229" },
      { label: "Actual Goal", color: "#A66999" },
    ],
  },
  GrossProfit: [
    { label: "Previous Year", color: "#1875B5" },
    { label: "Last Year", color: "#FF7F0E" },
    { label: "Current Year", color: "#29A229" },
    { label: "Actual Goal", color: "#A66999" },
  ],
  NetIncome: [
    { label: "Previous Year", color: "#1875B5" },
    { label: "Last Year", color: "#FF7F0E" },
    { label: "Current Year", color: "#29A229" },
  ],
  OperatingIncome: [
    { label: "Previous Year", color: "#1875B5" },
    { label: "Last Year", color: "#FF7F0E" },
    { label: "Current Year", color: "#29A229" },
  ],
};

const pipChartColorsLegend = [
  { color: "#609DD4", label: "Plan Revenue" },
  { color: "#0AB156", label: "Actual Revenue PIP" },
  { color: "#FCF30A", label: "Original Goal" },
  { color: "#FB986B", label: "Last Year Revenue " },
  // { color: "#609DD4", label: "% to Plan PIP" },
  // { color: "#FB986B", label: "% to Last Year PIP" },
];

const pipChartColorsLegendWip = [{ color: "#609DD4", label: "Plan Revenue" }];

const pipChartColors = [
  { color: "#609DD4", label: "Plan Revenue" },
  // { color: "#0AB156", label: "Actual Revenue PIP" },
  // { color: "#FCF30A", label: "Original Goal" },
  // { color: "#FB986B", label: "Last Year Revenue" },
  // { color: "#609DD4", label: "% to Plan PIP" },
  // { color: "#FB986B", label: "% to Last Year PIP" },
  { color: "#609DD4", label: "Plan Revenue" },
  // { color: "#0AB156", label: "Actual Revenue PIP" },
  // { color: "#FCF30A", label: "Original Goal" },
  // { color: "#FB986B", label: "Last Year Revenue" },
  { color: "#609DD4", label: "Plan Revenue" },
  // { color: "#0AB156", label: "Actual Revenue PIP" },
  // { color: "#FCF30A", label: "Original Goal" },
  // { color: "#FB986B", label: "Last Year Revenue" },
];

const SummaryChartBranch = (props) => {
  const weeks = [];
  for (let i = 1; i <= 52; i++) {
    weeks.push(i);
  }
  const sourceDoc = [
    { branch: "Overland Park", source: "138ce1b4-08d4-4950-886a-bf8297a37973" },
    { branch: "Austin", source: "f22bf4dd-db99-4c4a-a88f-23a100553e99" },
    { branch: "Brigham City", source: "7e68eb16-1129-4b48-afc8-7d0268445a07" },
    { branch: "Broomfield", source: "a552dcfa-c777-4f37-84b2-6a8ee7fc6ee1" },
    {
      branch: "Colorado Springs",
      source: "332134e7-5b3d-4aa0-b823-8f7b64b2d6fc",
    },
    { branch: "Dallas", source: "d7ea9471-9bd0-4aa6-a3e1-4ccaf15ca9ea" },
    { branch: "Inez", source: "ea6ff918-bd2a-4198-acee-4ca2ef72a732" },
    { branch: "Kingman", source: "fec604a1-cb7a-4052-a225-7c2d51dfddd2" },
    { branch: "Logan", source: "aff5178d-1979-44dd-8179-3bfc0f5cd5f3" },
    { branch: "Longview", source: "0ba07053-e626-4045-82f4-342026d632cc" },
    { branch: "Chehalis", source: "f50207bf-a049-4c57-a281-818c4a806c42" },
    { branch: "Spirit Lake", source: "369658a8-cc83-4b6e-b39c-a7892ee985fd" },
    { branch: "Tolleson", source: "86730d71-dfc0-4023-9a37-ea7600a36621" },
    { branch: "Tooele", source: "73a2f8ff-214d-4cad-ba95-bf0a0c110a63" },
    { branch: "West Valley", source: "c1d07427-2249-43c4-86ea-2632e6ed07e6" },
  ];
  useEffect(() => {
    const filteredSource = sourceDoc.find((d) => d.branch === props.name.label);
    if (filteredSource) {
      setSelectedSourceDoc(filteredSource.source);
    }
  }, []);

  const Weeks = useSelector((state) => state.filterReducer.allWeekLabels);

  const [allWeeks, setAllWeeks] = useState([]);
  const [weekLabels, setWeekLabels] = useState([]);
  const [selectedSourceDoc, setSelectedSourceDoc] = useState();

  useEffect(() => {
    const allWeeksData = groupByKey(Weeks, "CalendarYear");
    setAllWeeks(allWeeksData);
    setWeekLabels(
      props.isTopClientsReport
        ? weeks
        : allWeeksData?.["2024"]?.map((w) => w.WeekEndingDate)
    );
  }, [Weeks]);
  const enableTooltip = useSelector(
    (state) => state.companySummaryReducer.enableTooltip
  );
  const showPipData = useSelector(
    (state) => state.companySummaryReducer.showPipSummaryLines
  );
  const tempChartValues = {
    Revenue: {
      Exp: {
        [`${labelFormatter("Previous Year")}`]: [],
        [`${labelFormatter("Last Year")}`]: [],
        [`${labelFormatter("Current Year")}`]: [],
        [`${labelFormatter("Actual Goal")}`]: [],
      },
      InExp: {
        [`${labelFormatter("Previous Year")}`]: [],
        [`${labelFormatter("Last Year")}`]: [],
        [`${labelFormatter("Current Year")}`]: [],
        [`${labelFormatter("Actual Goal")}`]: [],
        [`${labelFormatter("12 Months")}`]: [],
      },
    },
    GrossProfit: {
      [`${labelFormatter("Previous Year")}`]: [],
      [`${labelFormatter("Last Year")}`]: [],
      [`${labelFormatter("Current Year")}`]: [],
      [`${labelFormatter("Actual Goal")}`]: [],
    },
    NetIncome: {
      [`${labelFormatter("Previous Year")}`]: [],
      [`${labelFormatter("Last Year")}`]: [],
      [`${labelFormatter("Current Year")}`]: [],
    },
    OperatingIncome: {
      [`${labelFormatter("Previous Year")}`]: [],
      [`${labelFormatter("Last Year")}`]: [],
      [`${labelFormatter("Current Year")}`]: [],
    },
  };
  const [isExp, setIsExp] = useState(false);
  const [chartColors, setChartColors] = useState([]);
  const [colors, setColors] = useState(chartColors);
  const [series, setSeries] = useState([]);
  const [options, setOptions] = useState({
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    title: {},
    legend: {
      position: "top",
      show: false,
      horizontalAlign: "right",
      tooltipHoverFormatter: function (val, opts) {
        return val;
      },
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    markers: {
      size: [1, 1, 1, 0],
      strokeWidth: 0,
      hover: {
        sizeOffset: 6,
      },
    },
  });
  const dispatch = useDispatch();
  const showBranchClients = () => {
    dispatch(setEnableTooltip(true));
    dispatch(setShowBranchClientsRanking(true));
    props.setSelectedBranch(props.location.name);
    props.setLevel(props.location.level);
    props.setTotalType(props.location.name);
  };

  useEffect(() => {
    setOptions({
      ...options,
      xaxis: {
        labels: {
          style: {
            fontSize: "5px",
          },
        },
        categories: weekLabels,
      },
    });
  }, [weekLabels]);

  useEffect(() => {
    if (weekLabels?.length > 0)
      if (
        showPipData &&
        props.pipData &&
        props.dataMode === "Revenue" &&
        props.executive
      ) {
        setChartPipDataWip();
      } else {
        if (Object.keys(props.data).length > 0) {
          setChartData();
        }
      }
  }, [
    props.data,
    props.dataMode,
    enableTooltip,
    props.sameMaxValue,
    showPipData,
    props.pipData,
    weekLabels,
  ]);

  const setChartData = () => {
    const isExperienced =
      props.branchManagerDays.includes("Y") || props.branchManagerDays === "";
    setIsExp(isExperienced);
    const tempSeriesKeys =
      props.dataMode === "Revenue"
        ? props.goal || isExperienced
          ? tempChartValues.Revenue.Exp
          : tempChartValues.Revenue.InExp
        : tempChartValues[props.dataMode];
    const chartSeries = [
      ...Object.keys(tempSeriesKeys).map((v, key) => {
        return {
          name: labelFormatter(v),
          data: [...getWeekData(props.data[v], props.dataMode)],
        };
      }),
    ];
    const maxValue = getMaxRoundedValue(
      Math.max(
        ...Object.values(chartSeries).flatMap(({ data }) =>
          [].concat(...Object.values(data)).filter(d=>d!==null)
        )
      )
    );

    const minValue = getMinRoundedValue(
      Math.min(
        ...Object.values(chartSeries).flatMap(({ data }) =>
          [].concat(...Object.values(data)).filter(d=>d!==null)
        )
      )
    );
    let colorsTemp =
      props.dataMode === "Revenue"
        ? props.goal || isExperienced
          ? initialColors.Revenue.Exp
          : initialColors.Revenue.InExp
        : initialColors[props.dataMode];
    setChartColors(colorsTemp);
    setColors(colorsTemp);
    setOptions({
      ...options,
      stroke: {
        width: [1, 1, 1, 1],
        dashArray:
          props.dataMode === "Revenue"
            ? props.goal || isExperienced
              ? [0, 0, 0, 2]
              : [0, 0, 0, 2, 2]
            : [0, 0, 0, 2],
        curve: "straight",
        colors: colorsTemp.map((v) => v.color),
      },
      markers: {
        size:
          props.dataMode === "Revenue"
            ? props.goal || isExperienced
              ? [1, 1, 1, 0]
              : [1, 1, 1, 0, 0]
            : [1, 1, 1, 0],
        strokeWidth: 0,
        hover: {
          sizeOffset: 6,
        },
      },
      tooltip: {
        enabled: enableTooltip,
        enabledOnSeries:
          props.dataMode === "Revenue"
            ? props.goal || isExperienced
              ? [0, 1, 2, 3]
              : [0, 1, 2, 3, 4]
            : [0, 1, 2, 3],
        followCursor: false,
        fixed: {
          enabled: true,
          position: "topRight",
          offsetX: 0,
          offsetY: -150,
        },
      },
      colors: colorsTemp.map((v) => v.color),
      xaxis: {
        categories: weekLabels,
        labels: {
          style: {
            fontSize: "5px",
          },
        },
      },
      yaxis: {
        labels: {
          show: true,
          style: {
            fontSize: "7px",
          },
          formatter: (v) => formatAmount(v),
        },
        max:
          props.dataMode === "Revenue"
            ? props.sameMaxValue
              ? props.revenue.RevSARMaxValue
              : props.revenue.RevMaxValue ?? undefined
            : props.dataMode === "GrossProfit"
            ? props.sameMaxValue
              ? props.revenue.GpSARMaxValue
              : props.revenue?.GpMaxValue ?? undefined
            : maxValue ,
        min:
          props.dataMode === "Revenue"
            ? props.sameMaxValue
              ? props.revenue.RevSARMinValue
              : props.revenue.RevMinValue ?? 0
            : props.dataMode === "GrossProfit"
            ? props.sameMaxValue
              ? props.revenue.GpSARMinValue
              : props.revenue?.GpMinValue ?? 0
            : minValue,
        tickAmount: 5,
      },
    });
    setSeries(chartSeries);
  };

  const setChartPipDataWip = () => {
    if (props.pipData) {
      let markersData = {},
        pipData = {};
      Object.keys(allWeeks).map((year) => {
        let markers = [];
        let planrevenue = [];
        allWeeks[year]?.map(({ WeekEndingDate, CalendarWeekNumber }) => {
          const v = props.pipData?.find(
            (d) => d.WeekEndingDate === WeekEndingDate.toString()
          );
          if (v) {
            if (v.DotsColor)
              markers.push({ week: CalendarWeekNumber, color: v.DotsColor });
            planrevenue.push(v.PlanRevenue);
          } else {
            planrevenue.push(null);
          }
        });
        markersData = { ...markersData, [year]: markers };
        pipData = {
          ...pipData,
          [year]: {
            planrevenue,
          },
        };
      });
      const isExperienced =
        props.branchManagerDays.includes("Y") || props.branchManagerDays === "";
      setIsExp(isExperienced);
      const tempSeriesKeys =
        props.dataMode === "Revenue"
          ? props.goal || isExperienced
            ? tempChartValues.Revenue.Exp
            : tempChartValues.Revenue.InExp
          : tempChartValues[props.dataMode];
      const chartSeries = [
        ...Object.keys(tempSeriesKeys).map((v, key) => {
          return {
            name: labelFormatter(v),
            data: [...getWeekData(props.data[v], props.dataMode)],
          };
        }),
      ];

      const seriesData = [
        ...Object.values(pipData).flatMap((data) => {
          return [{ name: "Plan Revenue", data: data.planrevenue }];
        }),
      ];

      const maxValue = getMaxRoundedValue(
        Math.max(
          ...Object.values(chartSeries).flatMap(({ data }) =>
            [].concat(...Object.values(data)).filter(d=>d!==null)
          ),
          ...Object.values(seriesData).flatMap(({ data }) =>
            [].concat(...Object.values(data)).filter(d=>d!==null)
          )
        )
      );

      const minValue = getMinRoundedValue(
        Math.min(
          ...Object.values(chartSeries).flatMap(({ data }) =>
            [].concat(...Object.values(data)).filter(d=>d!==null)
          ),
          ...Object.values(seriesData).flatMap(({ data }) =>
            [].concat(...Object.values(data)).filter(d=>d!==null)
          )
        )
      );

      const markerOptions = [];
      Object.values(markersData).map((markers, index) => {
        const weekNumbers = allWeeks[2024].map((w) => w.CalendarWeekNumber);
        markers.map((marker) => {
          markerOptions.push({
            seriesIndex: index,
            dataPointIndex: weekNumbers.indexOf(marker.week),
            fillColor: marker.color,
            strokeColor: marker.color,
            size: 2,
            shape: "circle",
          });
        });
      });

      let colorsTemp =
        props.dataMode === "Revenue"
          ? props.goal || isExperienced
            ? initialColors.Revenue.Exp
            : initialColors.Revenue.InExp
          : initialColors[props.dataMode];

      setOptions({
        ...options,
        tooltip: {
          enabled: enableTooltip,
          enabledOnSeries:
            props.dataMode === "Revenue"
              ? props.goal || isExperienced
                ? [0, 1, 2, 3]
                : [0, 1, 2, 3, 4]
              : [0, 1, 2, 3],
          followCursor: false,
          fixed: {
            enabled: true,
            position: "topRight",
            offsetX: 0,
            offsetY: -150,
          },
        },
        stroke: {
          width: 1,
          dashArray:
            props.dataMode === "Revenue"
              ? props.goal || isExperienced
                ? [0, 0, 0, 2, 0, 0, 0]
                : [0, 0, 0, 2, 2, 0, 0, 0]
              : [0, 0, 0, 2, 0, 0, 0],
          curve: "straight",
          colors: [...colorsTemp, ...pipChartColors].map((v) => v.color),
        },
        colors: [...colorsTemp, ...pipChartColors].map((v) => v.color),
        markers: {
          discrete: markerOptions,
          hover: {
            size: 2,
            sizeOffset: 2,
          },
        },
        yaxis: [
          ...chartSeries.map((s, i) => {
            return {
              floating: i > 0,
              seriesName: s?.name,
              labels: {
                show: i === 0,
                formatter: (v) => formatAmount(v),
                style: {
                  fontSize: "7px",
                },
              },
              max:
                props.dataMode === "Revenue"
                  ? props.sameMaxValue
                    ? props.revenue.RevSARMaxValue
                    : props.revenue.RevMaxValue ?? maxValue
                  : props.dataMode === "GrossProfit"
                  ? props.sameMaxValue
                    ? props.revenue.GpSARMaxValue
                    : props.revenue?.GpMaxValue ?? maxValue
                  : maxValue,
              min:
                props.dataMode === "Revenue"
                  ? props.sameMaxValue
                    ? props.revenue.RevSARMinValue
                    : props.revenue.RevMinValue ?? 0
                  : props.dataMode === "GrossProfit"
                  ? props.sameMaxValue
                    ? props.revenue.GpSARMinValue
                    : props.revenue?.GpMinValue ?? 0
                  : minValue,
              tickAmount: 5,
            };
          }),
          ...seriesData.map((s) => {
            return {
              floating: true,
              seriesName: s?.name,
              labels: {
                show: false,
                formatter: (v) => formatAmount(v),
              },
              max:
                props.dataMode === "Revenue"
                  ? props.sameMaxValue
                    ? props.revenue.RevSARMaxValue
                    : props.revenue.RevMaxValue ?? maxValue
                  : props.dataMode === "GrossProfit"
                  ? props.sameMaxValue
                    ? props.revenue.GpSARMaxValue
                    : props.revenue?.GpMaxValue ?? maxValue
                  : maxValue,
              min:
                props.dataMode === "Revenue"
                  ? props.sameMaxValue
                    ? props.revenue.RevSARMinValue
                    : props.revenue.RevMinValue ?? 0
                  : props.dataMode === "GrossProfit"
                  ? props.sameMaxValue
                    ? props.revenue.GpSARMinValue
                    : props.revenue?.GpMinValue ?? 0
                  : minValue,
              tickAmount: 5,
            };
          }),
        ],
        xaxis: {
          categories: weekLabels,
          labels: {
            show: true,
            style: {
              fontSize: "5px",
            },
          },
        },
      });
      setSeries([...chartSeries, ...seriesData]);
      setColors([...colorsTemp, ...pipChartColorsLegendWip]);
    }
  };

  const [modalOpened, { open, close }] = useDisclosure(false);

  const getPercentChange = () => {
    return props.dataMode === "Revenue"
      ? formatPercChange(props.revenue?.PercChangeRev ?? 0)
      : props.dataMode === "GrossProfit"
      ? formatPercChange(props.revenue?.PercChangeGP ?? 0)
      : props.dataMode === "NetIncome"
      ? formatPercChange(props.revenue?.PercChangeNI ?? 0)
      : formatPercChange(props.revenue?.PercChangeOI ?? 0);
  };

  const getPercentOverlyChange = () => {
    return props.dataMode === "Revenue"
      ? formatPercChange(props.revenue?.peroverlyRev ?? 0)
      : props.dataMode === "GrossProfit"
      ? formatPercChange(props.revenue?.peroverlyGP ?? 0)
      : props.dataMode === "NetIncome"
      ? formatPercChange(props.revenue?.peroverlyNI ?? 0)
      : formatPercChange(props.revenue?.peroverlyOI ?? 0);
  };

  return (
    <div className="company-summary-charts">
      <div className="chart-header">
        <div className="flex items-end w-full">
          <div className="manager-revenue justify-end">
         
            {props.dataMode === "Revenue" &&
              props.executive &&
              showPipData &&
              props.pipMilestonesData && (
                <div className="status-area flex w-full flex-col justify-end text-xs">
                  Current Status:
                  <div
                    className={`w-full border border-slate-400 flex items-center justify-center h-8 text-center ${
                      props.pipMilestonesData?.StatusColor !== "#ffff00"
                        ? "text-white"
                        : ""
                    } font-semibold`}
                    style={{
                      background: props.pipMilestonesData.StatusColor,
                    }}
                  >
                    {props.pipMilestonesData.Milestone}
                  </div>
                </div>
              )}
            {(props.regionManager || props.districtManager) && (
              <div className="manager-area-left border-0">
                <div className={"text-[12px] w-full flex"}>
                  {props.regionManager
                    ? `Region Manager: ${props.regionManager}`
                    : props.districtManager
                    ? `District Manager: ${props.districtManager}`
                    : ``}
                </div>
              </div>
            )}
            <div>
              {props.revenue && (
                <div className="branch-revenues">
                  <div className={"branch-revenue-box revenue"}>
                    {millionConverter(props.revenue?.ProjectedRevenue ?? 0)}
                  </div>
                  <div className={"branch-revenue-box oi"}>
                    {props.revenue["OI%"] ?? 0}%
                  </div>
                  <div className={"branch-revenue-box trailing"}>
                    {millionConverter(
                      props.revenue[
                        `${
                          props.sortValue === "TWGPRank"
                            ? "Trailing4WeeksGP"
                            : "Trailing4WeeksRevenue"
                        }`
                      ]
                    ) ?? 0}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="flex justify-center name-area">
            <div
              className={`chart-header-title text-center cursor-pointer`}
              style={{
                background:
                  props.dataMode === "Revenue" &&
                  props.executive &&
                  showPipData &&
                  props.pipMilestonesData?.BranchColor
                    ? props.pipMilestonesData?.BranchColor
                    : "",
              }}
              title="Show Top Clients"
              onClick={showBranchClients}
            >
              {props.name.label}
            </div>
          </div>
          <div className="manager-area-right">
            <div className="border-[1px] border-slate-300">
              {props.manager?.length > 0 && (
                <div className={"w-full flex gap-1 items-center"}>
                  <div className="w-[60%]">Branch Manager:</div>
                  <div className="w-[40%] bold">{props.manager}</div>
                </div>
              )}
              <div className={"w-full flex gap-1 items-center"}>
                <div className="w-[60%]">BM Employment Time:</div>
                <div className="w-[40%] bold">
                  {props.branchManagerDays !== ""
                    ? props.branchManagerDays
                    : "--"}
                </div>
              </div>
              {props.manager?.length > 0 && (
                <div className={"w-full flex gap-1 items-center"}>
                  <div className="w-[60%]">Sales Manager:</div>
                  <div className="w-[40%] bold">{props.salesManager}</div>
                </div>
              )}
              <div className={"w-full flex gap-1 items-center"}>
                <div className="w-[60%]">SM Employment Time:</div>
                <div className="w-[40%] bold">
                  {props.salesManagerDays !== ""
                    ? props.salesManagerDays
                    : "--"}
                </div>
              </div>
            </div>
            {props.executive && (
              <div className="branch-revenues w-full flex justify-end">
                <div
                  className="branch-revenue-box flex justify-center items-center  percentage"
                  title="Week over week %"
                >
                  {getPercentChange()}
                </div>
                <div
                  className="branch-revenue-box flex justify-center items-center  percentage2"
                  title="% over last year"
                >
                  {getPercentOverlyChange()}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <SummaryBranchChartArea
        selectedSourceDoc={selectedSourceDoc}
        colors={colors}
        options={options}
        series={series}
        showPipData={showPipData}
        {...props}
        open={open}
        modalOpened={modalOpened}
        revenue={props.revenue}
        dataMode={props.dataMode}
      />
      <Modal
        opened={modalOpened}
        onClose={close}
        title={props.location.name}
        centered
        size="100%"
      >
        <div className="h-[82vh] flex items-end w-full">
          <SummaryBranchChartArea
            selectedSourceDoc={selectedSourceDoc}
            colors={colors}
            options={options}
            series={series}
            showPipData={showPipData}
            {...props}
            open={open}
            height="700"
            modalOpened={modalOpened}
          />
        </div>
      </Modal>
    </div>
  );
};

export const SummaryBranchChartArea = (props) => {
  const { revenue, dataMode, selectedSourceDoc } = props;
  const url = `https://ascendstaff.sharepoint.com/:w:/r/_layouts/15/Doc.aspx?sourcedoc=%7B${selectedSourceDoc}%7D&action=default&mobileredirect=true`;

  const getPercChange = () => {
    return dataMode === "Revenue"
      ? formatPercChange(revenue.PercChangeRev)
      : dataMode === "GrossProfit"
      ? formatPercChange(revenue.PercChangeGP)
      : dataMode === "NetIncome"
      ? formatPercChange(revenue.PercChangeNI)
      : formatPercChange(revenue.PercChangeOI);
  };

  return (
    <div className="chart-area flex flex-col w-full">
      <div className="chart-legend-area">
        <div className="colors-area">
          {props.colors.map((v, key) => (
            <div className="color-box" key={key}>
              <div
                className={
                  dottedLine(v.label)
                    ? `w-4 border-dotted border-y-2 border-x-0 border-[${v.color}]`
                    : "color-circle"
                }
                style={{ backgroundColor: `${v.color}` }}
              ></div>
              <div className={"color-name"}>{labelFormatter(v.label)}</div>
            </div>
          ))}
        </div>
       

        <div className="flex gap-2 items-center justify-center ">
             {props.name.label==="Virtual Tulsa" && (
               <Popover width={200} position="right" withArrow shadow="md">
          <Popover.Target>
          <i className="material-icons cursor-pointer" style={{ fontSize: "20px" }}>info_outline</i>
          </Popover.Target>
          <Popover.Dropdown>
            <div className="text-xs">Data is only available up to February, so no projection, OI% and Trailing 4 weeks are being calculated.</div>
          </Popover.Dropdown>
          </Popover>
          )}
          {props.executive && (
            !props.modalOpened && (
              <div className="cursor-pointer" onClick={props.open}>
                <i className="material-icons" style={{ fontSize: "16px" }}>
                  zoom_in
                </i>
              </div>
            )
          )}
          </div>
      </div>
      <div className={"h-full flex-grow"}>
        <ApexChart
          options={props.options}
          series={props.series}
          height={props.height ?? 250}
        />
        {!props.wip && (
          <div
            className={`notes flex flex-col justify-end mt-2 ${
              // props.dataMode === "Revenue" &&
              props.executive && props.showPipData ? "h-10" : ""
            }`}
          >
            <div className="flex w-full">
              {
                // props.dataMode === "Revenue" &&
                props.executive &&
                  props.showPipData &&
                  props.pipMilestonesData && (
                    <span className="text-[10px] px-2">
                      Company Summary Notes:
                    </span>
                  )
              }
              {props.executive && props.revenue && (
                <CompanySummaryNotes
                  notes={props.revenue.Notes}
                  noteId={props.revenue.noteId}
                  location={props.location}
                />
              )}
            </div>
            {
              // props.dataMode === "Revenue" &&
              props.executive &&
                props.showPipData &&
                props.pipMilestonesData && (
                  <div className="flex flex-col">
                    <div className="flex w-full">
                      <span className="text-[10px] px-2">
                        PIP Summary Notes:
                      </span>
                      <PipSummaryNotes
                        notes={props.pipMilestonesData.Notes}
                        noteId={props.pipMilestonesData.noteId}
                        location={props.location}
                      />
                    </div>

                    {/* <Popover width={500} position="right" withArrow shadow="md">
                    <Popover.Target>
                      <div
                        className="text-[10px] px-2 cursor-pointer hover:underline hover:text-blue-700 w-fit"
                      >
                        Document
                      </div>
                    </Popover.Target>
                    <Popover.Dropdown>
                      
                      <iframe
                        src="https://ascendstaff.sharepoint.com/_layouts/15/Doc.aspx?sourcedoc={f22bf4dd-db99-4c4a-a88f-23a100553e99}&amp;action=embedview"
                        src={embedUrl}
                        width="476px"
                        height="288px"
                      >
                        This is an embedded{" "}
                        <a target="_blank" href="https://office.com">
                          Microsoft Office
                        </a>{" "}
                        document, powered by{" "}
                        <a target="_blank" href="https://office.com/webapps">
                          Office
                        </a>
                        .
                      </iframe>
                    
                    </Popover.Dropdown>
                  </Popover> */}
                  </div>
                )
            }
            {selectedSourceDoc && props.executive && (
              <div className="border-t-[1px] border-black">
                <a
                  className="text-[10px] text-blue-500 px-2 cursor-pointer hover:underline hover:text-blue-700 font-semibold "
                  href={url}
                  target="_blank"
                >
                  Document
                </a>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
// @ts-ignore
class ApexChart extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    // @ts-ignore
    return (
      <>
        <ReactApexChart
          key={!!this.props.options?.markers?.discrete}
          options={this.props.options}
          series={this.props.series}
          type="line"
          height={this.props.height}
        />
      </>
    );
  }
}

export default SummaryChartBranch;
