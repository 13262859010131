/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import * as actions from "./actionTypes";

export const initialState = {
  milestonesData: {
    region: [],
    district: [],
    legacy_branch: [],
    new_branch: [],
  },
  branchesData: [],
  regionsData: [],
  districtsData: [],
  pipRegions: [],
};

interface IAction {
  type: String;
  payload: any;
}

export const pipSummaryReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case actions.SET_PIP_MILESTONE_DATA:
      return {
        ...state,
        milestonesData: action.payload,
      };
    case actions.SET_PIP_REGION_DATA:
      return {
        ...state,
        regionsData: action.payload,
      };
    case actions.SET_PIP_DISTRICT_DATA:
      return {
        ...state,
        districtsData: action.payload,
      };
    case actions.SET_PIP_BRANCH_DATA:
      return {
        ...state,
        branchesData: action.payload,
      };
    case actions.SET_PIP_REGIONS:
      return {
        ...state,
        pipRegions: action.payload,
      };
    default:
      return state;
  }
};
