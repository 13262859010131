/*
 * Copyright (C) 2023 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { useEffect, useState } from "react";
import { groupByKey } from "../../../utils/group";
import { useDispatch, useSelector } from "react-redux";
import {
  getRegions,
  getRegionsExceptQcsa,
} from "../../../store/Filter/actions";
import "../../../assets/scss/DailyNumberSelector.scss";

export const DailyNumberSelector = ({
  level,
  selectedBranch,
  setSelectedBranch,
  setLevel,
  setTotalType,
  setBranches,
  kearlKurve,
}: any) => {
  const [regions, setRegions] = useState([] as any);
  const [districts, setDistricts] = useState([] as any);
  const [subAreas, setSubAreas] = useState([] as any);
  const [areaSelected, setAreaSelected] = useState([] as any);
  const [showAllRegions, setShowAllRegions] = useState<string | null>("");
  const [showDistricts, setShowDistricts] = useState<string | null>("");
  const [showUngroupedRegions, setShowUngroupedRegions] =
    useState<boolean>(false);
  const [subAreaSelected, setSubAreaSelected] = useState<string | null>(null);
  const [regionSelected, setRegionSelected] = useState<string | null>(null);

  const setBranch = (level: string, branch: any) => {
    setLevel(level);
    if (setTotalType) setTotalType(branch.Title);
    setSelectedBranch(branch.Location);
  };
  const {
    allRegions,
    hierarchy,
    areaList,
    subAreaList,
    regionsList,
    districtList,
    branchesList,
  } = useSelector((state: any) => state.filterReducer);
  const dispatch = useDispatch();

  const loadRegions = async () => {
    dispatch(kearlKurve ? await getRegionsExceptQcsa() : await getRegions());
  };

  useEffect(() => {
    setSubAreas(subAreaList);
    setRegions(regionsList);
    setDistricts(districtList);

    if (kearlKurve && setBranches) setBranchesList(branchesList);
  }, [subAreaList, regionsList, districtList, branchesList]);

  const setBranchesList = (branches: any) => {
    if (kearlKurve) {
      setBranches(
        branches.sort((a: any, b: any) => {
          return a > b ? 1 : a < b ? -1 : 0;
        })
      );
    }
  };

  const setAreaLevel = (branch: any) => {
    setSubAreaSelected(null);
    setRegionSelected(null);
    setShowUngroupedRegions(true);
    setShowAllRegions(null);
    setShowDistricts(null);
    setLevel("Area");
    setAreaSelected(branch);
    setBranch("Area", { Location: branch, Title: "Area" });
    setSubAreas(
      Object.keys(hierarchy[branch]).filter((v) => v !== "Ungrouped")
    );
    setRegions(
      Object.values(
        groupByKey(Object.values(hierarchy[branch]).flat(), "Region")
      ).map((v: any) => ({
        Region: v[0].Region,
        AreaName: v[0].Area,
        SubAreaName: v[0].SubAreaName,
      })) ?? []
    );
    setDistricts(
      Object.values(
        groupByKey(Object.values(hierarchy[branch]).flat(), "District")
      ).map((v: any) => ({
        District: v[0].District,
        Region: v[0].Region,
        AreaName: v[0].Area,
        SubAreaName: v[0].SubAreaName,
      })) ?? []
    );

    setBranchesList(
      allRegions
        .filter((v: any) => v["Areaname"] === branch)
        .map((v: any) => v.Branch)
    );
  };

  const setSubareaLevel = (branch: any) => {
    setShowDistricts(null);
    setShowUngroupedRegions(false);
    setShowAllRegions(branch);
    setLevel("SubArea");
    setSubAreaSelected(branch);
    setRegionSelected(null);

    setBranch("Subarea", { Location: branch, Title: "Subarea" });

    setRegions(
      Object.values(
        groupByKey(
          allRegions.filter((v: any) => v["Sub Area Name"] === branch),
          "Region"
        )
      ).map((v: any) => ({
        Region: v[0].Region,
        AreaName: v[0].Areaname,
        SubAreaName: v[0]["Sub Area Name"],
      })) ?? []
    );
    setDistricts(
      Object.values(
        groupByKey(
          allRegions.filter((v: any) => v["Sub Area Name"] === branch),
          "District"
        )
      ).map((v: any) => ({
        District: v[0].District,
        Region: v[0].Region,
        AreaName: v[0].Areaname,
        SubAreaName: v[0]["Sub Area Name"],
      })) ?? []
    );
    setBranchesList(
      allRegions
        .filter((v: any) => v["Sub Area Name"] === branch)
        .map((v: any) => v.Branch)
    );
  };

  const setRegionLevel = (branch: any) => {
    // setBranch("Region", branch);
    setRegionSelected(branch.Region);
    setSubAreaSelected(branch.SubAreaName);
    setLevel("Area");
    setDistricts(
      Object.values(
        groupByKey(
          allRegions.filter((v: any) => v.Region === branch.Region),
          "District"
        )
      ).map((v: any) => ({
        District: v[0].District,
        Region: v[0].Region,
        AreaName: v[0].Areaname,
        SubAreaName: v[0]["Sub Area Name"],
      })) ?? []
    );
    setBranch("Region", { Location: branch.Region, Title: "Region" });
    setBranchesList(
      allRegions
        .filter((v: any) => v["Region"] === branch.Region)
        .map((v: any) => v.Branch)
    );
  };

  const setDistrictLevel = (branch: any) => {
    setLevel("District");
    const districtTemp: any = allRegions.find(
      (v: any) => v.District === branch.District
    );
    setRegionSelected(districtTemp ? districtTemp.Region : "");
    setSubAreaSelected(districtTemp ? districtTemp["Sub Area Name"] : "");
    setBranch("District", { Location: branch.District, Title: "Area Branch" });
    setBranchesList(
      allRegions
        .filter((v: any) => v["District"] === branch.District)
        .map((v: any) => v.Branch)
    );
  };

  const setCompanyLevelADA = () => {
    setShowAllRegions(null);
    setShowDistricts(null);
    setSubAreaSelected(null);
    setRegionSelected(null);
    setShowUngroupedRegions(false);
    setLevel("ADA");
    setBranch("ADA", { Location: "ADA_Branch", Title: "ADA Company" });
    // setSubAreaList([]);
    // setRegionsList([]);
    // setDistrictList([]);
    setBranchesList([
      "ADA Arlington",
      "ADA Call Center - Inactive",
      "ADA Forth Worth",
      "ADA Houston",
    ]);
  };

  useEffect(() => {
    loadRegions();
  }, []);

  useEffect(() => {
    if (level === "Company") {
      setShowAllRegions("Company");
      setShowDistricts("Company");
      setSubAreaSelected(null);
      setRegionSelected(null);
      setShowUngroupedRegions(false);
      setSubAreas(Object.keys(groupByKey(allRegions, "Sub Area Name")));
      setRegions(
        Object.values(groupByKey(allRegions, "Region")).map((v: any) => ({
          Region: v[0].Region,
          AreaName: v[0].Areaname,
          SubAreaName: v[0]["Sub Area Name"],
        }))
      );
      setDistricts(
        Object.values(groupByKey(allRegions, "District")).map((v: any) => ({
          District: v[0].District,
          Region: v[0].Region,
          AreaName: v[0].Area,
          SubAreaName: v[0].SubAreaName,
        })) ?? []
      );
      if (setBranches) setBranchesList(allRegions.map((v: any) => v.Branch));
    }
  }, [level]);

  return (
    <div className="branches-section  h-fit 2xl:min-h-[200px]">
      <div
        className={`top-hierarchy flex justify-center -ml-[7rem]
        `}
      >
        <div
          className={`branch-menu ${
            selectedBranch === "ADA_Branch" ? "active" : ""
          }`}
          onClick={() => setCompanyLevelADA()}
        >
          ADASTAFF, Inc.
        </div>

        <div
          className={`branch-menu ${
            selectedBranch === "Company" ? "active" : ""
          }`}
          onClick={() =>
            setBranch("Company", { Location: "Company", Title: "Company" })
          }
        >
          Ascend Staffing
        </div>
      </div>
      <div className="mid-hierarchy flex justify-center">
        {level !== "ADA" &&
          areaList.map(
            (v: any, key: number) =>
              v && (
                <div
                  key={key}
                  className={`branch-menu ${
                    areaSelected === v.Location ? "bg-gray " : ""
                  } ${selectedBranch === v ? "active" : ""}`}
                  onClick={() => setAreaLevel(v)}
                >
                  {v}
                </div>
              )
          )}
      </div>
      {subAreaList.length > 0 && (
        <div className="mid-hierarchy flex justify-center">
          {level !== "ADA" &&
            subAreas.map(
              (v: any, key: number) =>
                v &&
                v !== "Ungrouped" && (
                  <div
                    key={key}
                    className={`branch-menu ${
                      selectedBranch === v ? "active" : ""
                    }`}
                    onClick={() => {
                      setSubareaLevel(v);
                    }}
                  >
                    {v}
                  </div>
                )
            )}
          {level !== "ADA" &&
            showUngroupedRegions &&
            regions
              .filter((region: any) => region.SubAreaName === "Ungrouped")
              .map(
                (v: any, key: any) =>
                  v.Region && (
                    <div
                      key={key}
                      className={`branch-menu leading-none ${
                        selectedBranch === v.Region ? "active" : ""
                      }`}
                      onClick={() => {
                        setRegionLevel(v);
                        setShowDistricts(null);
                        setShowAllRegions(null);
                      }}
                    >
                      {v.Region}
                    </div>
                  )
              )}
        </div>
      )}
      <div className="low-hierarchy flex justify-center">
        {level !== "ADA" &&
          showAllRegions &&
          regions
            .filter(
              (region: any) =>
                region.SubAreaName !== "Ungrouped" &&
                (!subAreaSelected || region.SubAreaName === subAreaSelected)
            )
            .map(
              (v: any, key: any) =>
                v.Region && (
                  <div
                    key={key}
                    className={`branch-menu ${
                      selectedBranch === v.Region ? "active" : ""
                    }`}
                    onClick={() => {
                      setRegionLevel(v);
                      setShowDistricts(v.Region);
                      setShowUngroupedRegions(false);
                    }}
                  >
                    {v.Region}
                  </div>
                )
            )}
        {level !== "ADA" &&
          showAllRegions &&
          regions
            .filter(
              (region: any) =>
                region.SubAreaName === "Ungrouped" &&
                (!subAreaSelected || region.SubAreaName === subAreaSelected)
            )
            .map(
              (v: any, key: any) =>
                v.Region && (
                  <div
                    key={key}
                    className={`branch-menu ${
                      selectedBranch === v.Region ? "active" : ""
                    }`}
                    onClick={() => {
                      setRegionLevel(v);
                      setShowUngroupedRegions(
                        v.SubAreaName === "Ungrouped" ? true : false
                      );
                      setShowAllRegions(
                        v.SubAreaName === "Ungrouped" ? null : v.Region
                      );
                    }}
                  >
                    {v.Region}
                  </div>
                )
            )}
      </div>
      <div className="lowest-hierarchy flex justify-center">
        {level !== "ADA" &&
          showDistricts &&
          districts
            ?.filter((v: any) =>
              regionSelected ? v.Region === regionSelected : true
            )
            .map(
              (v: any, key: any) =>
                v.District && (
                  <div
                    key={key}
                    className={`branch-menu ${
                      selectedBranch === v.District ? "active" : ""
                    }`}
                    onClick={() => {
                      setDistrictLevel(v);
                      setShowUngroupedRegions(
                        v.SubAreaName === "Ungrouped" ? true : false
                      );
                      setShowAllRegions(
                        v.SubAreaName === "Ungrouped" ? null : v.Region
                      );
                    }}
                  >
                    {v.District}
                  </div>
                )
            )}
      </div>
    </div>
  );
};
