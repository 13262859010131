/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import {
  DeleteRequest,
  GetRequest,
  PostRequest,
  PutRequest,
} from "../plugins/https";

export const APIGetCompanySummaryRegions = () => {
  return GetRequest("company-summary/regions");
};
export const APIGetCompanySummaryRegionsWithoutVirtual = () => {
  return GetRequest("company-summary/regions-without-virtual");
};
export const APIGetADABranches = () => {
  return GetRequest("company-summary/ada-branches");
};

export const APICompanyTotalData = () => {
  return GetRequest("company-summary/total");
};

export const APIAreaTotalData = () => {
  return GetRequest("company-summary/area");
};

export const APISubareaTotalData = () => {
  return GetRequest("company-summary/sub-area");
};

export const APIRegionTotalData = () => {
  return GetRequest("company-summary/region");
};
export const APIDistrictTotalData = () => {
  return GetRequest("company-summary/district");
};

export const APIBranchTotalData = () => {
  return GetRequest("company-summary/branch");
};

export const APIADATotalData = () => {
  return GetRequest("company-summary/ada");
};
export const APIADABranchTotalData = () => {
  return GetRequest("company-summary/ada-branch");
};

export const APIGetCompanySummaryByRegionName = (region: any) => {
  return GetRequest(`/company-summary/regions/${region}`);
};

export const APICompanyTotalRevenue = () => {
  return GetRequest("company-summary/total-revenue");
};

export const APIAddNotes = (data: any) => {
  return PostRequest("company-summary/notes", data);
};

export const APIUpdateNotes = (id: any, data: any) => {
  return PutRequest("company-summary/notes/" + id, data);
};

export const APIFetchNotes = (branch: any) => {
  return GetRequest("company-summary/notes/" + branch);
};

export const APIDeleteNote = (id: any) => {
  return DeleteRequest("company-summary/notes/" + id);
};

export const FetchClientsRevenueRankByLocation = (
  location: string,
  rank: any,
  year: any
) => {
  if (location === "Company") {
    return GetRequest(
      `company-summary/clients/rev-rank?rank=${rank}&year=${year}`
    );
  }
  return GetRequest(
    `company-summary/clients/rev-rank/${location}?rank=${rank}&year=${year}`
  );
};

export const FetchClientsGPRankByLocation = (
  location: string,
  rank: any,
  year: any
) => {
  if (location === "Company") {
    return GetRequest(
      `company-summary/clients/gp-rank?rank=${rank}&year=${year}`
    );
  }
  return GetRequest(
    `company-summary/clients/gp-rank/${location}?rank=${rank}&year=${year}`
  );
};

export const fetchClientDetailsByLocation = (
  clientName: any,
  year: any,
  location: any
) => {
  if (location === "Company") {
    return GetRequest(
      `company-summary/client-details?year=${year}&clientName=${encodeURIComponent(
        clientName
      )}`
    );
  }
  return GetRequest(
    `company-summary/client-details/locations/${location}?year=${year}&clientName=${encodeURIComponent(
      clientName
    )}`
  );
};

// wip routes

export const APIGetCompanySummaryRegionsWip = () => {
  return GetRequest("/wip/company-summary/regions");
};

export const WipAPICompanyTotalData = () => {
  return GetRequest("/wip/company-summary/total");
};

export const WipAPIAreaTotalData = () => {
  return GetRequest("/wip/company-summary/area");
};

export const WipAPISubareaTotalData = () => {
  return GetRequest("/wip/company-summary/sub-area");
};

export const WipAPIRegionTotalData = () => {
  return GetRequest("/wip/company-summary/region");
};

export const WipAPIBranchTotalData = () => {
  return GetRequest("/wip/company-summary/branch");
};
export const WipAPICompanyTotalRevenue = () => {
  return GetRequest("/wip/company-summary/total-revenue");
};

// goal routes

export const GoalAPICompanyTotalData = () => {
  return GetRequest("/goal/company-summary/total");
};

export const GoalAPIAreaTotalData = () => {
  return GetRequest("/goal/company-summary/area");
};

export const GoalAPISubareaTotalData = () => {
  return GetRequest("/goal/company-summary/sub-area");
};

export const GoalAPIRegionTotalData = () => {
  return GetRequest("/goal/company-summary/region");
};

export const GoalAPIBranchTotalData = () => {
  return GetRequest("/goal/company-summary/branch");
};
