/*
 * Copyright (C) 2023 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { useEffect, useState } from "react";
import { APIFetchEmployeeRedeployment } from "../api/employee-redeployment";
import { CustomDatePicker } from "../components/common/CustomDatePicker";
import { ActionIcon, Select, Table } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../components/common/Loader";
import { setLoading } from "../store/Filter/actions";
import EmployeeRedeploymentSelector from "../components/modules/employeeDeployment/EmployeeRedeploymentSelector";
import { sortByKeyDailyNumbers } from "../utils/helper/cellFormatter";

export const EmployeeRedployment = () => {
  const [areaList, setAreaList] = useState([] as any);
  const [subAreaList, setSubAreaList] = useState([] as any);
  const [regionsList, setRegionsList] = useState([] as any);
  const [districtsList, setDistrictsList] = useState([] as any);
  const [hierarchy, setHierarchy] = useState({} as any);
  const [level, setLevel] = useState("Company");
  const [selectedLevelBranch, setSelectedLevelBranch] =
    useState<string>("Company");
  const [subAreaSelected, setSubAreaSelected] = useState(null);
  const [areaSelected, setAreaSelected] = useState(null);
  const [regionSelected, setRegionSelected] = useState(null);
  const [districtSelected, setDistrictSelected] = useState(null);
  const [branchesList, setBranchesList] = useState([] as any);
  const dispatch = useDispatch();
  const [branches, setBranches] = useState<any>(["All"]);
  const [clients, setClients] = useState<any>(["All"]);
  const [tableData, setTableData] = useState([]);
  const [totalData, setTotalData] = useState<any>({});
  const [opened, { open, close }] = useDisclosure();
  const [selectedDateRange, setSelectedDate] = useState({
    startdate: moment(new Date()).add("-29", "days").format("yyyy-MM-DD"),
    enddate: moment(new Date()).format("yyyy-MM-DD"),
  });
  const [selectedBranch, setSelectedBranch] = useState("All");
  const [selectedClient, setSelectedClient] = useState("All");
  const loading = useSelector((state: any) => state.filterReducer.loading);
  const [sortedData, setSortedData] = useState<any>(tableData);
  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: string;
  } | null>({
    key: "Redeployed %",
    direction: "desc",
  });

  const headers = [
    { label: "Branch", value: "Branch" },
    {
      label: "# of Employees that came off Assignment",
      value: "EmployeesOffAssignment",
    },
    {
      label: "# of Employees that were Re-Assigned",
      value: "EmployeesReassigned",
    },
    { label: "Redeployed %", value: "Redeployed%" },
  ];

  useEffect(() => {
    document.title = "Employee Redeployment";
  }, []);

  const getIcon = (label: string) => {
    return sortConfig?.key !== label
      ? "unfold_more"
      : sortConfig?.direction === "asc"
      ? "arrow_drop_down"
      : "arrow_drop_up";
  };

  const requestSort = (key: any, direction: any) => {
    if (direction === "asc") {
      const sorted = sortByKeyDailyNumbers(tableData, key, false);
      setSortedData(sorted);
    } else {
      const sorted = sortByKeyDailyNumbers(tableData, key, true);
      setSortedData(sorted);
    }
  };
  useEffect(() => {
    requestSort(sortConfig?.key, sortConfig?.direction);
  }, [sortConfig, tableData]);

  useEffect(() => {
    dispatch(setLoading(true));

    APIFetchEmployeeRedeployment(
      selectedDateRange.startdate,
      selectedDateRange.enddate,
      selectedBranch,
      selectedClient,
      selectedLevelBranch === "Company" ? "All" : selectedLevelBranch
    ).then(({ data }) => {
      setClients(["All", ...data.clients]);
      setBranches(["All", ...data.branches]);
      setTableData(data.data);
      const total = data.data.reduce(
        (acc: any = {}, curr: any) => {
          acc.AssignedTotal += curr["# of Employees that came off Assignment"];
          acc.ReassignedTotal += curr["# of Employees that were Re-Assigned"];
          return { ...curr, ...acc };
        },
        { AssignedTotal: 0, ReassignedTotal: 0 }
      );
      setTotalData(total);
      dispatch(setLoading(false));
    });
  }, [selectedDateRange, selectedBranch, selectedClient, selectedLevelBranch]);

  const maxEmployeesOffAssignment =
    tableData && tableData.length > 0
      ? Math.max(
          ...tableData.map((d) => d["# of Employees that came off Assignment"])
        )
      : 0;

  const maxEmployeesReAssigned =
    tableData && tableData.length > 0
      ? Math.max(
          ...tableData.map((d) => d["# of Employees that were Re-Assigned"])
        )
      : 0;

  const maxRedeployedPercentage =
    tableData && tableData.length > 0
      ? Math.max(...tableData.map((d) => d["Redeployed %"]))
      : 0;

  return (
    <section className="flex flex-col items-center w-[100vw] gap-5 h-[100vh] bg-client-light overflow-auto">
      {loading && <Loader />}
      <div className="absolute top-0 left-0 flex items-center">
        <ActionIcon>
          <a
            href="https://app.powerbi.com/groups/d9341e4a-2be0-4eb6-8eb1-5a9ed297ac2a/reports/60da792a-d41f-48b5-a002-80366a29f241/ReportSectionfdcec90ba28704f33c71?experience=power-bi"
            target={"_blank"}
            rel="noreferrer"
          >
            <i
              className="text-slate-600 material-icons"
              style={{ fontSize: "28px" }}
            >
              home
            </i>
          </a>{" "}
        </ActionIcon>
      </div>
      <header className="w-full flex flex-col items-center gap-2">
        <div className="text-center text-lg md:text-2xl p-1 font-semibold">
          Employee Redeployment
        </div>

        <EmployeeRedeploymentSelector
          selectedLevelBranch={selectedLevelBranch}
          setSelectedLevelBranch={setSelectedLevelBranch}
          hierarchy={hierarchy}
          setHierarchy={setHierarchy}
          areaList={areaList}
          setAreaList={setAreaList}
          subAreaList={subAreaList}
          setSubAreaList={setSubAreaList}
          regionsList={regionsList}
          setRegionsList={setRegionsList}
          districtList={districtsList}
          setDistrictList={setDistrictsList}
          branchesList={branchesList}
          setBranchesList={setBranchesList}
          areaSelected={areaSelected}
          setAreaSelected={setAreaSelected}
          subAreaSelected={subAreaSelected}
          setSubAreaSelected={setSubAreaSelected}
          regionSelected={regionSelected}
          setRegionSelected={setRegionSelected}
          districtSelected={districtSelected}
          setDistrictSelected={setDistrictSelected}
          setSelectedBranch={setSelectedBranch}
          setSelectedClientName={setSelectedClient}
          setLevel={setLevel}
          level={level}
        />

        <div className="filters flex flex-col sm:flex-row gap-0 sm:gap-5 sm:max-md:gap-2 px-1 lg:w-[64rem] w-[95%]">
          <div className="w-full sm:max-md:w-[16rem] md::w-[21rem]">
            <Select
              id="redeployment-date"
              size="xs"
              className="bg-client-light"
              data={[]}
              label="Date"
              onClick={() => {
                opened ? close() : open();
              }}
              placeholder={
                selectedDateRange.startdate +
                "  to  " +
                selectedDateRange.enddate
              }
            />
            {opened && (
              <div className="absolute z-30">
                <CustomDatePicker
                  close={close}
                  selectedDate={selectedDateRange}
                  setSelectedDate={setSelectedDate}
                />
              </div>
            )}
          </div>
          <Select
            className="w-full sm:max-md:w-[16rem] md::w-[21rem] bg-client-light"
            data={branches}
            label="Branch"
            size="xs"
            value={selectedBranch}
            onChange={(value: any) => setSelectedBranch(value)}
          />
          <Select
            className="w-full sm:max-md:w-[16rem] md::w-[21rem] bg-client-light"
            data={clients}
            label="Client Name"
            size="xs"
            value={selectedClient}
            onChange={(value: any) => setSelectedClient(value)}
          />
        </div>
      </header>
      <main className="overflow-auto h-[82dvh] lg:w-[64rem] w-[95%]">
        <Table
          fontSize="xs"
          verticalSpacing={1}
          horizontalSpacing={1}
          withColumnBorders
          className="bg-white"
        >
          <thead className="sticky top-0 shadow-sm h-8 z-20 bg-tertiary">
            <tr>
              {headers.map((header: any) => {
                return (
                  <th
                    key={header.value}
                    style={{ color: "#FFFFFF", paddingLeft: "5px" }}
                    className="min-w-[120px] md:min-w-[200px]"
                  >
                    <div className="flex justify-between items-center">
                      {header.label}
                      <i
                        className="material-icons cursor-pointer"
                        style={{ fontSize: "20px" }}
                        onClick={() =>
                          setSortConfig({
                            key: header.label,
                            direction:
                              sortConfig === null ||
                              sortConfig.direction === "desc"
                                ? "asc"
                                : "desc",
                          })
                        }
                      >
                        {getIcon(header.label)}
                      </i>
                    </div>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {!loading &&
              (sortedData.length > 0 ? (
                sortedData.map((data: any) => {
                  return (
                    <tr>
                      <td style={{ paddingLeft: "5px" }}>{data.Branch}</td>
                      <td className="text-center">
                        <div
                          className="bg-[#a8d5e0] relative h-5"
                          style={{
                            width:
                              maxEmployeesOffAssignment === 0
                                ? 0
                                : (data[
                                    "# of Employees that came off Assignment"
                                  ] /
                                    maxEmployeesOffAssignment) *
                                  300,
                          }}
                        >
                          <div className="absolute left-10 text-xs">
                            {data["# of Employees that came off Assignment"] ===
                            0
                              ? "None"
                              : data["# of Employees that came off Assignment"]}
                          </div>
                        </div>
                      </td>
                      <td className="text-center">
                        <div
                          className="bg-[#a8d5e0] relative h-5"
                          style={{
                            width:
                              maxEmployeesReAssigned === 0
                                ? 0
                                : (data[
                                    "# of Employees that were Re-Assigned"
                                  ] /
                                    maxEmployeesReAssigned) *
                                  300,
                          }}
                        >
                          <div className="absolute left-10 text-xs">
                            {data["# of Employees that were Re-Assigned"] === 0
                              ? "None"
                              : data["# of Employees that were Re-Assigned"]}
                          </div>
                        </div>
                      </td>
                      <td className="text-center">
                        <div
                          className="bg-[#a8d5e0] relative h-5 -z-1"
                          style={{
                            width:
                              maxRedeployedPercentage === 0
                                ? 0
                                : (data["Redeployed %"] /
                                    maxRedeployedPercentage) *
                                  100,
                          }}
                        >
                          <div className="absolute left-10 text-xs">
                            {data["Redeployed %"] === null
                              ? "None"
                              : (data["Redeployed %"] === 0
                                  ? data["Redeployed %"]
                                  : data["Redeployed %"].toFixed(2)) + "%"}
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <td colSpan={4}>
                  <div className="py-40 flex items-center justify-center text-sm">
                    No data available
                  </div>
                </td>
              ))}
          </tbody>
          {!loading && tableData.length > 0 ? (
            <tfoot className="sticky h-8 bottom-0 bg-stone-100">
              <tr>
                <th>Total</th>
                <th style={{ textAlign: "center" }}>
                  {totalData.AssignedTotal}
                </th>
                <th style={{ textAlign: "center" }}>
                  {totalData.ReassignedTotal}
                </th>
                <th style={{ textAlign: "center" }}>-</th>
              </tr>
            </tfoot>
          ) : (
            <></>
          )}
        </Table>
      </main>
    </section>
  );
};
